import React, { Component } from 'react';
import AppBar from './AppBar';
import VirtualizedList from './VirtualizedList';
import { IProduct, IFarm, IPedido, IMe, ISilo, IIPedido } from '../types';
import IconButton from '@material-ui/core/IconButton';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import CommentIcon from '@material-ui/icons/Comment';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import DoIcon from '@material-ui/icons/OpenInNewOutlined';
import PropTypes from 'prop-types';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { ListChildComponentProps } from 'react-window';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ArrowIcon from '@material-ui/icons/ArrowForwardIos';
import EditIcon from '@material-ui/icons/EditOutlined';
import DuplicateIcon from '@material-ui/icons/UpdateOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import StarIconRounded from '@material-ui/icons/StarBorderRounded';
import StarIconFilled from '@material-ui/icons/StarOutlined';
import { calculateQuantityItems, GetFormattedPedidoDate, createArrayPedido, verifyDateTimeLimit, verifyCarga, CheckNumberPlate} from '../helpers'
import CreateModal from './CreateDialog'
import {cloneDeep} from 'lodash';
import StepsIcon from '@material-ui/icons/Pageview';
import DeliveryIcon from '@material-ui/icons/LocalShipping';
import PedidoStepper from './PedidoStepper'
import PriorityIcon from '@material-ui/icons/LowPriorityOutlined';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {sortBy, find} from 'lodash';
import Checkbox from '@material-ui/core/Checkbox';
import OrderService from '../ApiClients/OrderService';
import { withParamsAndNavigate } from './Material/ReactRouter';
import DeliveryDialog from './DeliveryDialog';

const useStyles = makeStyles(createStyles({
    img: {
        objectFit: 'contain',
    },
    root: {
        borderRadius: 0,
    },
    item: {
        // border: '1px solid #ccc',
        borderRadius: '15px',
        margin: '5px 10px',
        width: 'calc(100% - 20px)!important',
        boxShadow: '5px 5px 5px 0px rgba(0,0,0,0.31)',
        border: '1px solid #ccc',
        //cursor: 'pointer',
        position: 'relative',
    },
    promotion: {
        backgroundColor: '#f00',
        display: 'inline-block',
        padding: '3px 15px',
        color: '#fff',
        verticalAlign: 'middle',
    },
    quantIcon: {
        paddingTop: '3px',
        paddingBottom: '3px',
        color: '#284770',
        verticalAlign: 'middle',
    },
    tablecell: {
        fontSize: 12
    }
}));

const DistributionSummary = (props: any) => {
    const { index, style, data } = props;
    const classes = useStyles();
    let item: Array<ISilo> = data;

    return (
        <div style={style} key={index}>
            <Paper style={{display:'flex'}}>
                <Table style={{width:15}}>
                    <TableHead> 
                    <TableRow>
                        <TableCell padding="checkbox" size="small" className={classes.tablecell} align="center">{`Silo`}</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {sortBy(item, [function(o) { return o.orden; }]).map((row:ISilo, index:number) => (
                        <TableRow key={index}>
                            <TableCell style={{paddingBottom:'4px'}} padding="checkbox" size="small" align="center"><strong>{row.SILOID}</strong></TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
                <div style={{overflowX:'scroll'}}>
                    <Table>
                        <TableHead> 
                        <TableRow>
                            {/*<TableCell padding="checkbox" size="small" className={classes.tablecell} align="center">{`Cantidad`}</TableCell>*/}
                            <TableCell padding="checkbox" size="small" className={classes.tablecell} align="center">{`Orden`}</TableCell>
                            <TableCell padding="checkbox" size="small" className={classes.tablecell} align="center">{`Cantidad`}</TableCell>
                            <TableCell padding="checkbox" size="small" className={classes.tablecell} align="center">{`Lleno`}</TableCell>
                            <TableCell padding="checkbox" size="small" className={classes.tablecell} align="center">{`Resto`}</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {sortBy(item, [function(o) { return o.orden; }]).map((row:ISilo, indexSilo:number) => (
                            <TableRow key={indexSilo}>
                                <TableCell padding="checkbox" size="small" align="center"><strong>{row.orden}</strong></TableCell>
                                <TableCell padding="checkbox" size="small" align="center"><input id='siloQty' disabled type='number' style={{paddingLeft:'5px', paddingRight:'5px', width:'50px'}} value={String(row.quantity)} ></input></TableCell>
                                <TableCell padding="checkbox" size="small" align="center"><Checkbox id='siloLleno' disabled checked={row.lleno} color="primary" inputProps={{'aria-label': 'primary checkbox',}}/></TableCell>
                                <TableCell padding="checkbox" size="small" align="center"><Checkbox id='siloResto' disabled checked={row.resto} inputProps={{'aria-label': 'secondary checkbox',}} /></TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                </div>
            </Paper>
        </div>
    );
}

DistributionSummary.propTypes = {
    index: PropTypes.number,
    style: PropTypes.object,
} as any;

const ProductListItem = (props: any) => {
    const { index, style, data } = props;
    const classes = useStyles();
    let item: IProduct = data[index];
    const imageB64 = item.image?new Buffer(item.image).toString("base64"):'';
    console.log(item)
    return (
        <div style={style} key={index}>
            <ListItem button classes={{ root: classes.item }} /*{onClick={item.onItemClick}}*/>
                {item.TIPO == 1 && item.isInCart?
                    <ListItemIcon>
                    <IconButton onClick={item.onDistributionClick} className={classes.quantIcon}>
                        <PriorityIcon />
                    </IconButton>
                    </ListItemIcon>
                :
                    ''
                }
                <ListItemText 
                    style={{whiteSpace:'nowrap', overflowX:'auto'}}
                    secondaryTypographyProps={{ style: { whiteSpace: "normal" } }}
                    primary={item.ITEMNAME}
                    secondary={
                        <div>
                            <div>
                                {`Etapa: ${item.feedStage} / Lote: ${item.batchId}`}
                            </div>
                            <div>
                                {`Cantidad pedida: ${item.quantity?item.quantity:0} ${item.unitId}`}
                            </div>
                            {item.quantityDelivered?
                                <div>
                                    {`Cantidad entregada: ${item.quantityDelivered} ${item.unitId}`}
                                </div>
                            :
                                null
                            }
                        </div>
                    } 
                />
                {/*<ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="Comments" onClick={item.onItemClick}>
                        <ArrowIcon />
                    </IconButton>
                </ListItemSecondaryAction>*/}
            </ListItem>
        </div>
    );
}

ProductListItem.propTypes = {
    index: PropTypes.number,
    style: PropTypes.object,
} as any;

interface ISummaryProps {
    params?:any;
    navigate?:any;    
    farm?: IFarm;
    cart: Array<IProduct>;
    cartDate?: MaterialUiPickersDate;
    cartComments?: string;
    fullScreen?: boolean;
    goBack?: Function;
    isPedido?: boolean;
    pedidoId?: number;
    status: number;
    pedido?: IPedido;
    updateCart: Function;
    resetCart: Function;
    myInfo?:IMe;
    updateQuantityCart?:Function;
    type: number;
}

interface ISummaryState {
    openCommentDialog: boolean;
    creationModalOpen: boolean;
    inputText: any;
    modalType: number;
    titleModal:string;
    favourite: boolean;
    typeAction: number;
    loadingModal: boolean;
    cartDate: any;
    pedido?: IPedido;
    openStepperDialog:boolean;
    openDeliveryDialog: boolean;
    inputDeliveryDialog: any;
}

class Summary extends Component<ISummaryProps, ISummaryState> {
    constructor(props: ISummaryProps) {
        super(props);
        this.state = {
            openCommentDialog: false,
            openStepperDialog: false,
            creationModalOpen: false,
            inputText:'',
            titleModal:'',
            modalType: -1,
            typeAction: -1,
            favourite: false,
            loadingModal: false,
            cartDate: this.props.cartDate,
            pedido: this.props.pedido,

            openDeliveryDialog: false,
            inputDeliveryDialog: ''
        };
    }

    handleCommentClick = () => {
        this.setState({ openCommentDialog: !this.state.openCommentDialog });
    }

    handleCloseComment = () => {
        this.setState({ openCommentDialog: false });
    }

    handleStepperClick = () => {
        this.setState({ openStepperDialog: !this.state.openStepperDialog });
    }

    handleCloseStepper = () => {
        this.setState({ openStepperDialog: false });
    }

    handleItemClick = (product: IProduct, index: number) => {
        //this.setState({ detail: product })
        if(this.props.farm === undefined){

        } else {
            this.props.navigate(`/granja/${this.props.farm.dataareaid}/${this.props.farm.id}/producto/${product.ITEMID}`)
        }
    }
    
    onCloseCreationModal = () => {
        this.setState({ creationModalOpen: false });
    }
    
    onCloseDeliveryDialog = () => {
        this.setState({ openDeliveryDialog: false });
    }

    onToggleCreationModal = () => {
        this.setState({ creationModalOpen: !this.state.creationModalOpen });
    }

    handleDeleteOrder = () => {
        if(this.props.pedido !== undefined && this.props.farm !== undefined) {
            this.setState({
                inputText: `¿Quiere eliminar el pedido ${this.props.pedido.id} de la tienda ${this.props.farm.name}?`,
                modalType: 2,
                creationModalOpen: true,
                typeAction: 1
            })
        }
    }

    handleSendOrder = () => {
        if(this.props.farm !== undefined){
            if (this.controlExistingDatetime(this.props.farm.dataareaid)) {
                return;
            }
            if (!this.controlCarga()) {
                return;
            }
            if (!this.controlSilos()) {
                return;
            }
            this.setState({
                inputText: `¿Quiere tramitar el pedido de la granja ${this.props.farm.name}?`,
                modalType: 2,
                creationModalOpen: true,
                typeAction: 3
            })
        }
    }

    handleHeaderChange = (id: string, evt: any, pedido:IPedido) => {
        let n_pedido: IPedido = cloneDeep(pedido);

        n_pedido[id] = evt.target.value;

        if(id == "deliveryTruckNumberplate" || id == "deliveryTrailerNumberplate")
            n_pedido[id] = n_pedido[id]?.trim().toUpperCase();

        this.setState({
            pedido: n_pedido
        }, ()=>this.handleDeliveryOrder())
    }

    handleChange = (product:IProduct, evt: any, index: number, pedido:IPedido) => {
        let n_pedido: IPedido = cloneDeep(pedido);

        let quantity:string = evt.target.value;

        if(quantity.length == 0)
            n_pedido.products[index].quantityDelivered = 0;
        else
            n_pedido.products[index].quantityDelivered = Math.floor(Number(evt.target.value));

        this.setState({
            pedido: n_pedido
        }, ()=>this.handleDeliveryOrder())
    }

    handleDeliveryOrder = () => {
        /*if(this.props.farm !== undefined){
            this.setState({
                inputText: `¿Quiere entregar el pedido de la granja ${this.props.farm.name}?`,
                modalType: 2,
                creationModalOpen: true,
                typeAction: 6
            })
        }*/
        const {pedido} = this.state;

        if(!pedido)
            return;

        let n_pedido = cloneDeep(pedido);

        let n_products:Array<IProduct> = pedido.products.map((i:IProduct, index:number)=>{
            return {
                ...i,
                quantityDelivered: (i.quantityDelivered == null?i.quantity:i.quantityDelivered),
                handleChange: (product:IProduct, evt: any) => this.handleChange(product, evt, index, pedido),
            }
        })

        n_pedido.handleChange = (id: string, evt: any) => this.handleHeaderChange(id, evt, pedido);
        n_pedido.products = n_products;

        this.setState({
            titleModal:'PROCESO DE ENTREGA',
            inputDeliveryDialog: <DeliveryDialog style={{}} index={-1} data={[1, n_pedido]} />,
            modalType: 4,
            typeAction: 6,
            openDeliveryDialog: true
        })
    }

    handleDuplicateOrder= () => {
        if(this.props.pedido !== undefined && this.props.farm !== undefined) {
            this.setState({
                inputText: `¿Quiere duplicar el pedido ${this.props.pedido.id}?`,
                modalType: 2,
                creationModalOpen: true,
                typeAction: 6
            })
        }
    }

    handleCreateModal = (typeAction: number) => {
        switch(typeAction) {
            case 1: // Cuando el modal es para eliminar una orden
                this.deleteOrder();
                break;
            case 2: // Cuando el modal implica que se ha generado correctamente un pedido
                this.afterSaveOrDelete();
                break;
            case 3: // Cuando el modal es para tramitar pedido
                this.sendOrder();
                break;
            case 4: // Cuando el modal es para editar un pedido que ya había sido enviado para procesar
                //this.reOpenOrder();
                break;
            case 5: // Cuando el modal es para duplicar un pedido ya enviado
                if(this.props.pedido !== undefined && this.props.farm !== undefined) {
                    this.props.updateCart(this.props.pedido.products, this.props.farm.id, undefined, this.props.cartDate);
                    this.props.navigate(`/cesta`);
                }
                break;
            case 6: // Cuando el modal es para preguntar para entregar un pedido
                const {pedido} = this.state;
                console.log(pedido)

                if(pedido && pedido.farm){
                    // Controla los campos obligatorios
                    if(!pedido.deliveryTruckNumberplate || !CheckNumberPlate(1, pedido.deliveryTruckNumberplate))
                        this.setState({
                            inputText: `¡ATENCIÓN! Tiene que informar una matrícula de camión válida.`,
                            modalType: 1,
                            creationModalOpen: true,
                            typeAction: -1
                        })
                    else if(!pedido.deliveryTrailerNumberplate || !CheckNumberPlate(2, pedido.deliveryTrailerNumberplate))
                        this.setState({
                            inputText: `¡ATENCIÓN! Tiene que informar una matrícula de remolque válida.`,
                            modalType: 1,
                            creationModalOpen: true,
                            typeAction: -1
                        })
                    else
                    this.setState({
                        inputText: `¿Quiere entregar el pedido de la granja ${pedido.farm.name}?`,
                        modalType: 2,
                        creationModalOpen: true,
                        typeAction: 8
                    })
                }
                break;
            case 7: // Cuando el modal implica que se ha entregado correctamente un pedido
                this.afterDelivery();
                break;
            case 8: // Cuando el modal es para entregar un pedido
                this.sendDelivery();
                break;
            default:
                break;
        }
    }

    favouriteOrder = () => {
        if(this.state.favourite !== true) {
            return <BottomNavigationAction onClick={this.handleFavourite} label="Añadir a favoritos" icon={<StarIconRounded />} />
        }
        else {
            return <BottomNavigationAction onClick={this.handleFavourite} label="Quitar de favoritos" icon={<StarIconFilled />} />
        }
    }

    handleFavourite = () => {
        this.setState(prevState => ({
            favourite: !prevState.favourite
          }));
    }

    deleteOrder = async () => {
        if (this.props.pedido !== undefined && this.props.pedido.id !== undefined) {
            this.setState({
                loadingModal: true
            })
            //let response = await OrderService.deleteOrder(this.props.pedido.id, this.props.pedido.DATAAREAID);
            let response = {response:1};

            if(response['response'] === 1) {
                this.setState({
                    loadingModal: false
                })
                this.afterSaveOrDelete();
            } else {
                this.setState({
                    inputText: `¡ATENCIÓN! Se ha producido un error y no se ha podido eliminar el pedido.`,
                    modalType: 1,
                    creationModalOpen: true,
                    loadingModal: false
                })
            }
        }
    }

    sendOrder = async () => {
        // Verifica que el carro tiene artículos
        if(this.props.cart.length > 0) {

            // Verifica que no haya artículos con cantidad 0
            if(calculateQuantityItems(this.props.cart) === undefined) {

                // Verifica que la tienda y la fecha del carrito no sean undefined
                if(this.props.farm !== undefined && this.props.cartDate !== undefined) {
                    this.setState({
                        loadingModal: true
                    })
                    
                    // Crea el array del Pedido a actualizar o insertar
                    let Pedido : IPedido =
                    createArrayPedido(this.props.farm,
                                    this.props.cart,this.props.cartDate,
                                    this.props.cartComments,
                                    this.props.pedidoId, 10, 1
                                );
                    //console.log('Pedido',Pedido)
                    //let responsePedido : number = 1;
                    let responsePedido : number = await OrderService.saveData(Pedido, 1);

                    let text = "¡ATENCIÓN! Se ha producido un error y no se ha podido guardar el pedido.";
                    let modalType = 1;
                    let typeAction = -1;

                    switch(responsePedido){ // Condicional para verificar que no ha habido ningun error en el backend
                        case 1:
                            text = "Se ha generado el pedido correctamente.";
                            modalType = 3;
                            typeAction = 2;
                            break;
                        default:
                            break;
                    }

                    this.setState({
                        inputText: text,
                        modalType: modalType,
                        creationModalOpen: true,
                        typeAction:typeAction,
                        loadingModal: false
                    })
                } 
            } else {
                this.setState({
                    inputText: `¡ATENCIÓN! La cesta no puede contener artículos con cantidad 0.`,
                    modalType: 1,
                    creationModalOpen: true,
                    loadingModal: false
                })
            }
            
        } else {
            this.setState({
                inputText: `¡ATENCIÓN! La cesta debe contener mínimo 1 artículo.`,
                modalType: 1,
                creationModalOpen: true,
                loadingModal: false
            })
        }
    }

    sendDelivery = async () => {
        const {pedido} = this.state;
        // Verifica que la tienda y la fecha del carrito no sean undefined
        if(pedido && pedido.farm && pedido.date && pedido.id) {
            this.setState({
                loadingModal: true
            })
            
            // Crea el array del Pedido a actualizar o insertar
            // let Pedido : IPedido =
            let Pedido: IPedido = cloneDeep(pedido);
            Pedido.status = 50;
            // createArrayPedido(pedido.farm,
            //                 pedido.products,pedido.date,
            //                 pedido.comments,
            //                 pedido.id, 50, 1
            //             );
            // console.log('Pedido',Pedido)
            // let responsePedido : number = 1;
            let responsePedido : number = await OrderService.saveData(Pedido, 2);

            let text = "¡ATENCIÓN! Se ha producido un error y no se ha podido guardar el pedido.";
            let modalType = 1;
            let typeAction = -1;

            switch(responsePedido){ // Condicional para verificar que no ha habido ningun error en el backend
                case 1:
                    text = "Se ha entregado el pedido correctamente.";
                    modalType = 3;
                    typeAction = 7;
                    break;
                default:
                    break;
            }

            this.setState({
                inputText: text,
                modalType: modalType,
                creationModalOpen: true,
                typeAction:typeAction,
                loadingModal: false
            })
        } 
    }

    controlExistingDatetime = (dataareaid:string) => {
        if(this.props.farm){
            let dateTimePicker : Array<any> = verifyDateTimeLimit(this.state.cartDate, this.props.farm.timeLimitPedido, this.props.farm.beforeTimeLimitPedido, this.props.farm.festivos);
            this.setState({
                modalType: 1,
                inputText: `¡ATENCIÓN! La fecha de entrega se ha modificado para el ${GetFormattedPedidoDate(dateTimePicker[0])}.`,
                creationModalOpen: dateTimePicker[1],
                cartDate: dateTimePicker[0]
            })
            return dateTimePicker[1]
        }
        return false;
    }

    controlCarga = () => {
        let newCart = cloneDeep(this.props.cart);
        let itemsCero = 0;
        newCart.map((item:IProduct, index:number)=>{
            if(item.TIPO == 1 && (item.quantity == undefined || item.quantity == 0)){
                itemsCero++;
            }
        })
        /*let itemsAjusted = 0;
        let newCart = cloneDeep(this.props.cart);
        let settingFullTruck = find(fullTruck, function(o){return o.dataareaid == dataareaid});
        newCart.map((item:IProduct, index:number)=>{
            if(item.TIPO == 1 && settingFullTruck){
                let isCorrect:any = verifyCarga(6,item.quantity==undefined?0:item.quantity, settingFullTruck.full);
                if(!isCorrect[0]){
                    itemsAjusted++;
                    newCart[index].quantity = isCorrect[1]
                }
            }
        })

        if(itemsAjusted == 0){
            return true;
        }

        if(this.props.updateQuantityCart !== undefined){
            this.props.updateQuantityCart(newCart);
        }*/
        if(itemsCero == 0){
            return true;
        }
        this.setState({
            modalType: 1,
            inputText: `¡ATENCIÓN! Hay ${itemsCero} producto/s con cantidad 0.`,
            creationModalOpen: true,
        })
        return false;
    }

    controlSilos = () => {
        let badItems = 0;
        let badProduct = 0;
        let newCart = cloneDeep(this.props.cart);
        newCart.map((item:IProduct, index:number)=>{
            if(item.TIPO == 1){
                if(item.silos.length > 0){
                    item.silos.map((silo:ISilo, index:number)=>{
                        if(silo.lleno == undefined || silo.resto == undefined || silo.quantity == undefined){
                            badItems++
                        }
                    });
                } else {
                    badProduct++;
                }
            }
        })

        if(badItems == 0 && badProduct == 0){
            return true;
        }

        if(badProduct > 0){
            this.setState({
                modalType: 1,
                inputText: `¡ATENCIÓN! Hay ${badProduct} producto/s sin silos asignados.`,
                creationModalOpen: true,
            })
            return false;
        }

        this.setState({
            modalType: 1,
            inputText: `¡ATENCIÓN! Hay ${badItems} silo/s sin asignar cantidad.`,
            creationModalOpen: true,
        })
        return false;
    }

   
    afterSaveOrDelete = () => { // Resetea el carrito y envía al usuario en la pantalla de pedidos para ver los cambios
        this.props.resetCart();
        this.props.navigate(`/pedidos`);
    }

    afterDelivery = () => { // Resetea el carrito y envía al usuario en la pantalla de pedidos para ver los cambios
        this.props.navigate(`/delivery`);
    }

    onDistributionClick=(evt:any, product:IProduct, index:number)=>{
        evt.stopPropagation();
        this.setState({
            titleModal:'DISTRIBUCIÓN PIENSO',
            inputText: <DistributionSummary style={{}} index={index} data={product.silos} />,
            modalType: 1,
            typeAction: 1,
            creationModalOpen: true
        })
    }

    render() {
        const {pedido} = this.state;
        const {myInfo, type} = this.props;

        //let items = this.props.cart;

        let items = this.props.cart.map((product, i) => {
            let inCartProduct = this.props.cart.find((item) => {
                return (item.ITEMID + item.feedStage + item.batchId) === (product.ITEMID + product.feedStage + product.batchId);
            });
            let isInCart = inCartProduct !== undefined ? true : false;

            return {
                ...product,
                quantity: inCartProduct !== undefined ? inCartProduct.quantity : product.quantity,
                onItemClick: () => this.handleItemClick(product, i),
                onDistributionClick:(evt:any)=> this.onDistributionClick(evt, product, i),
                isInCart,
            }
        });

        return (
            <AppBar title={this.props.isPedido === true ? 'Detalles Pedido' : 'Confirmar Pedido'} navigate={this.props.navigate} goBack={this.props.goBack}>
                {this.props.cart.length !== 0 && this.props.farm !== undefined ?
                    <div style={{
                        padding: '15px',
                        borderRadius: '15px',
                        margin: '20px 10px',
                        width: 'calc(100% - 20px)!important',
                        boxShadow: '5px 5px 5px 0px rgba(0,0,0,0.31)',
                        border: '1px solid #ccc',
                        cursor: 'pointer',
                        position: 'relative',
                    }}>
                        <div style={{ marginTop: '0px' }}>
                            <b style={{ color: '#284770' }}>{this.props.farm.name}</b>
                            <br />
                            <span style={{ fontSize: '12px', color: '#555' }}>{this.props.farm.id}</span>
                            <br />
                            <b style={{ color: '#284770' }}>{this.props.pedidoId!==undefined?`Num. Pedido: ${this.props.pedido!==undefined && this.props.pedido.idPedido !== undefined?this.props.pedido.idPedido:''}`:`Nuevo pedido`}</b>
                            <br />
                            {'Fecha de Entrega '}{this.props.cartDate ? GetFormattedPedidoDate(this.state.cartDate) : ''}
                            <IconButton onClick={this.handleCommentClick} >
                                <CommentIcon />
                            </IconButton>
                            {/*<b style={{ color: '#284770' }}>{`Importe aproximado: `}{this.props.totalPrice!==undefined?`${this.props.totalPrice.toFixed(3)} €`:`---`}</b>*/}
                        </div>
                        <Dialog fullScreen={this.props.fullScreen} open={this.state.openCommentDialog} onClose={this.handleCloseComment} aria-labelledby="form-dialog-title">
                            <DialogTitle id="form-dialog-title">{'Comentarios'}</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    {'Escriba aquí sus comentarios...'}
                                </DialogContentText>
                                <TextField
                                    autoFocus
                                    multiline
                                    margin="dense"
                                    id="name"
                                    label="Comentarios"
                                    type="text"
                                    fullWidth
                                    value={this.props.cartComments}
                                    disabled
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={this.handleCloseComment} color="primary">
                                    {'Hecho'}
                                </Button>
                            </DialogActions>
                        </Dialog>

                        <Dialog fullScreen={false} open={this.state.openStepperDialog} onClose={this.handleCloseStepper} aria-labelledby="form-dialog-title">
                            <DialogTitle id="form-dialog-title">{'Trazabilidad'}</DialogTitle>
                            <DialogContent>
                                <PedidoStepper style={{}} index={1} data={this.props.pedido!==undefined?this.props.pedido.trazabilidad:undefined}/>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={this.handleCloseStepper} color="primary">
                                    {'Cerrar'}
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                    :
                    <div style={{ paddingTop: '10px' }}>
                        {'El camión está vacío'}
                    </div>
                }
                <div style={{
                    position: 'absolute',
                    top: '180px',
                    left: 0,
                    right: 0,
                    bottom: '56px',
                }}>
                    <VirtualizedList items={items} RowComponent={ProductListItem} itemSize={105} />
                </div>

                {this.state.openDeliveryDialog?
                    <CreateModal
                        onCloseCreationModal={this.onCloseDeliveryDialog}
                        isOpen={this.state.openDeliveryDialog}
                        inputText={this.state.inputDeliveryDialog}
                        typeModal={4}
                        action={this.handleCreateModal}
                        typeAction={6}
                        loadingModal={this.state.loadingModal}
                        titleModal={this.state.titleModal}
                        zIndex={0}
                    />
                :
                    null
                }

                <div style={{ position: 'absolute', bottom: 0, left: 0, right: 0 }}>
                    <CreateModal
                            onCloseCreationModal={this.onCloseCreationModal}
                            isOpen={this.state.creationModalOpen}
                            inputText={this.state.inputText}
                            typeModal={this.state.modalType}
                            action={this.handleCreateModal}
                            typeAction={this.state.typeAction}
                            loadingModal={this.state.loadingModal}
                            titleModal={this.state.titleModal}
                            zIndex={99}
                    />
                    {this.props.isPedido === true ?
                        //TODO: hide buttons if pedido is DONE and put products to card if edit
                        this.props.status == 0?
                            <BottomNavigation
                                showLabels
                                style={{ backgroundColor: '#ddd' }}
                            >
                                <BottomNavigationAction label="Borrar Pedido" icon={<DeleteIcon />} />
                                <BottomNavigationAction onClick={this.handleSendOrder} label="Tramitar Pedido" icon={<DoIcon />} />
                                <BottomNavigationAction onClick={this.handleStepperClick} label="Trazabilidad" icon={<StepsIcon />} />
                            </BottomNavigation>
                            :
                            this.props.status == 10?
                                <BottomNavigation
                                style={{ backgroundColor: '#ddd' }}
                                >
                                    <BottomNavigationAction onClick={this.handleStepperClick} label="Trazabilidad" icon={<StepsIcon />} />
                                </BottomNavigation>
                                :
                                    this.props.status == 40?
                                        <BottomNavigation
                                            showLabels
                                            style={{ backgroundColor: '#ddd' }}
                                        >
                                            {/*this.favouriteOrder()*/}
                                            {/*<BottomNavigationAction label="Duplicar" icon={<DuplicateIcon />} />*/}
                                            <BottomNavigationAction onClick={this.handleStepperClick} label="Trazabilidad" icon={<StepsIcon />} />
                                            {type == 2 && myInfo && myInfo.roles.some( ai => [30, 50, 100].includes(ai.role))?
                                                <BottomNavigationAction onClick={this.handleDeliveryOrder} label="Entregar" icon={<DeliveryIcon />} />
                                            :
                                                null
                                            }
                                        </BottomNavigation>
                                    :
                                    <BottomNavigation
                                    showLabels
                                    style={{ backgroundColor: '#ddd' }}
                                    >
                                        <BottomNavigationAction onClick={this.handleStepperClick} label="Trazabilidad" icon={<StepsIcon />} />
                                    </BottomNavigation>
                        :
                        <BottomNavigation
                            showLabels
                            style={{ backgroundColor: '#ddd' }}
                        >
                            <BottomNavigationAction onClick={this.handleSendOrder} label="Tramitar Pedido" icon={<DoIcon />} />
                        </BottomNavigation>
                    }
                </div>
            </AppBar>
        );
    }
}
export default withParamsAndNavigate(withMobileDialog()(Summary));