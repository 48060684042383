import React, { Component } from 'react';
import { IProduct, IMe } from '../types';
import { createStyles, withStyles, Theme } from '@material-ui/core/styles';
import AppBar from './AppBar';
import OrderService from '../ApiClients/OrderService';
import Loader from './Loader';
import { withParamsAndNavigate } from './Material/ReactRouter';

const styles = (theme: Theme) => createStyles({
    container: {
        padding: '10px',
        textAlign: 'center'
    },
    image: {
        width: '100%',
        height: 'auto'
    },
    promotion: {
        //backgroundColor: '#f00',
        padding: '3px 15px',
        //color: '#fff',
        verticalAlign: 'middle',
    },
});

interface IProductDetailProps {
    params?:any;
    navigate?:any;    
    classes: any;
}

interface IProductDetailState {
    product?: IProduct;
    loading: boolean;
}

class ProductDetail extends Component<IProductDetailProps, IProductDetailState>  {
    constructor(props: IProductDetailProps) {
        super(props);
        this.state = {
            loading: true
        };
    }

    componentDidMount() {
        this.loadProductDetails();
    }

    componentDidUpdate(prevProps: IProductDetailProps) {
        if (this.props.params.productRef !== prevProps.params.productRef || this.props.params.dataareaid !== prevProps.params.dataareaid) {
            this.loadProductDetails();
        }
    }

    loadProductDetails = async () => {
        let productRef = this.props.params.productRef;
        let dataareaid = this.props.params.dataareaid;

        let product:IProduct = await OrderService.getProduct(productRef, dataareaid);

        this.setState({ product, loading: false});
    }

    render() {
        const { classes } = this.props;

        const { product } = this.state;

        // let typeProperties : Array<string> = product !== undefined?getProductDescriptionColor(product.TIPO):['','','']
        const imageB64 = product && product.image?new Buffer(product.image).toString("base64"):'';

        return (
            <AppBar title={'Detalle de Producto'} navigate={this.props.navigate} >
                {product !== undefined ?
                    <div className={classes.container}>
                        <h3>{product.ITEMNAME}</h3>
                        <div className={classes.promotion} style={{backgroundColor:product.typeColor, color:'#ffffff'}}>
                            {product.typeDsc}
                        </div>
                        <p>{'Código: '}{product.ITEMID}</p>
                        <img
                            alt='' 
                            src={`data:image/svg;base64,${imageB64.substring(8,imageB64.length)}`}
                            className={classes.image}
                        />
                    </div>
                    :
                    this.state.loading ?
                        <div style={{marginLeft:'50%',marginTop:200}}>
                            <Loader loading={this.state.loading}/>
                        </div>
                    :
                    <div className={classes.container}>
                        <h3>{'Producto no disponible'}</h3>
                    </div>
                }
            </AppBar>
        );
    }
}
export default withParamsAndNavigate(withStyles(styles)(ProductDetail));