import { filter, split, reduce, find, sortBy, findIndex  } from 'lodash';
import { IFarm, IProduct, ISilo, IPedido, IFeedPlan, IFeed, IFestivo, IIPedido, IIFeedPlan } from './types';
import { adalApiFetch } from './adalConfig';

const fetchAPI = (
    url: string,
    method: string,
    parseJSON = false,
    body: any = undefined,
    headers = true
): Promise<any> => {
    return adalApiFetch(fetch, url, {
        method: method,
        mode: 'cors',
        redirect: 'follow',
        body: body ? JSON.stringify(body) : body,
        headers: headers
            ? new Headers({
                'Content-Type': 'application/json; charset=utf-8',
                'Accept': 'application/json; charset=utf-8',
            })
            : undefined
    }).then((response: any) => {
        if (response.ok) {
            if (parseJSON) {
                return response.json();
            }
        } else if (!parseJSON) {
            throw new Error(`Error ${response.status}`);
        } else {
            try {
                return response.json().then((data: any) => {
                    console.log('Error!');
                    return Promise.reject(new Error(data.message));
                });
            } catch (err) {
                throw new Error(`Error ${response.status}`);
            }
        }
        return response;
    });
}

const searchFarms = (inputData:Array<IFarm>, value: string) => {
    let lowerCaseValue = value.toLowerCase();
    let arrayValues : Array<string> = splitData(lowerCaseValue);
    let Farms : Array<IFarm> = inputData;

    arrayValues.forEach(element => {
        Farms = filter(Farms,function(item){
        return item['name'].toLowerCase().indexOf(element)!==-1 || String(item['id']).toLowerCase().indexOf(element)!==-1;
        });
    });

    return Farms;
}

const searchProducts = (inputData:Array<IProduct>, value: string) => {
    let lowerCaseValue = value.toLowerCase();
    let arrayValues : Array<string> = splitData(lowerCaseValue);
    let Products : Array<IProduct> = inputData;

    arrayValues.forEach(element => {
        Products = filter(Products,function(item){
        return item['ITEMNAME'].toLowerCase().indexOf(element)!==-1||
        item['typeDsc'].toString().toLowerCase().indexOf(element)!==-1;
        });
    });
    return Products;
}

const searchPedidos = (inputData:Array<IIPedido>, value: string) => {
    let lowerCaseValue = value.toLowerCase();
    let arrayValues : Array<string> = splitData(lowerCaseValue);
    let Pedidos : Array<IIPedido> = inputData;
    arrayValues.forEach(element => {
        Pedidos = filter(Pedidos,function(item){
            return item['farm']['name'].toLowerCase().indexOf(element)!==-1 || 
                item['idPedido'].toLowerCase().indexOf(element)!==-1 
                //|| getStatusDescriptionColor(item['status'])[0].toLowerCase().indexOf(element)!==-1;
                || item['statusDsc'].toLowerCase().indexOf(element)!==-1;
        });
    });

    return Pedidos;
}

const splitData = (input:string) => {
    return split(input,' ');
}

const GetFormattedDate = (inputData: any) => {
    var todayTime = new Date(inputData);
    var month = todayTime.getMonth() + 1;
    var day = todayTime.getDate();
    var year = todayTime .getFullYear();
    return year + "-" + (month < 10?'0'+month:month) + "-" + (day < 10?'0'+day:day)+"T00:00:00.000Z";
}

const GetDate = (inputData: any) => {
    var todayTime = new Date(inputData);
    var month = todayTime.getMonth() + 1;
    var day = todayTime.getDate();
    var year = todayTime .getFullYear();
    return year + "-" + (month < 10?'0'+month:month) + "-" + (day < 10?'0'+day:day);
}

const getNextOrden = (input:Array<ISilo>)=>{
    let orderedInput = sortBy(input, [function(o) { return o.orden; }]);
    for (let i = 0; i < orderedInput.length; i++) {
       
        if (orderedInput[i].orden !== (i + 1)) 
          return (i+1);
    }
    return orderedInput.length + 1; 
}

const getQuantitySilo = (capacidadSilo:number, quantity: number, cantidadAcum:number) => {
    if(!(cantidadAcum < quantity))
        return 0;
    
    let result = capacidadSilo - (quantity-cantidadAcum);
    if(result < 0){
        return capacidadSilo
    }
    return (quantity-cantidadAcum);
}

const getAcumuladoPienso = (input:Array<ISilo>) => {
    let cantidadAcum = reduce(
        input,
        function(accumulated, e){
            return accumulated + (e.quantity!==undefined?e.quantity:0);
        },
        0
    );

    return cantidadAcum;
}

const verifyDateTimeLimit=(inputData:any, maxHour: number, beforeLimitTime:number, festivos: Array<IFestivo>)=>{

    const isFestivo = (inputDay: Date) => { // No deja poner ni Sábado ni Domingo ni los días específicos como festivos
        if(inputDay.getDay() !== 0 && inputDay.getDay() !== 6 && findIndex(festivos, function(o){return new Date(o.fecha).toDateString() === inputDay.toDateString()}) == -1){
            return false;
        } else {
            return true;
        }
    }

    const verifyHour = (maxHour: number, currentDate: Date) => { // Verifica que no supere la hora máxima de pedido del día

        if((currentDate.getHours()*3600+(currentDate.getMinutes()*60)) < maxHour || maxHour === 0){
            return true;
        } else {
            return false;
        }
    }

    const avoidFestivo = (date: Date) =>{
        while(isFestivo(date)) {  // Verifica que no esté en sábado ni domingo la fecha seleccionada
            date.setDate(date.getDate() + 1)
            wasChanged = true;
        }
    }

    let currentDatetime:Date = new Date();
    let selectedDate:Date = new Date(inputData);
    
    let wasChanged = false;

    avoidFestivo(selectedDate);

    while(calcBusinessDays(currentDatetime, selectedDate, festivos) < beforeLimitTime){
        selectedDate.setDate(selectedDate.getDate() + 1);
        wasChanged = true;
    }

    if(!isFestivo(currentDatetime) && !verifyHour(maxHour, currentDatetime) && (calcBusinessDays(currentDatetime, selectedDate, festivos)) == beforeLimitTime){
        selectedDate.setDate(selectedDate.getDate() + 1);
        wasChanged = true;
    }

    avoidFestivo(selectedDate);
    
    return [selectedDate, wasChanged];
}
function calcBusinessDays(dDate1:Date, dDate2:Date, holidays:Array<IFestivo>){
 
    // clone date to avoid messing up original date and time
    var frD=new Date(dDate1.getTime()),
        toD=new Date(dDate2),
        numOfWorkingDays=0;
        
    
    // reset time portion
    frD.setHours(0,0,0,0);
    toD.setHours(0,0,0,0);
    //console.log('frD',frD)
    //console.log('toD',toD)

    // If current datetime is festivo then set to -1 number working days
    if(frD.getDay()==0||frD.getDay()==6||findIndex(holidays, function(o){return new Date(o.fecha).toLocaleDateString() == frD.toLocaleDateString()}) > -1){numOfWorkingDays = -1;}

    while(frD<toD){
        frD.setDate(frD.getDate()+1);
        var day=frD.getDay();
        if(day!=0&&day!=6&&findIndex(holidays, function(o){return new Date(o.fecha).toLocaleDateString() == frD.toLocaleDateString()}) == -1){numOfWorkingDays++;}
    }
    //console.log('numOfWorkingDays',numOfWorkingDays)
    return numOfWorkingDays;
}

const createArrayPedido = (farm:IFarm, cart: Array<IProduct> ,cartDate: any, 
    cartComments: string|undefined,
    idPedido:number|undefined, estado: number, moveType: number) => {
    let currentPedido : IPedido =  {
    business: farm.business,
    id: idPedido,
    farmId: farm.id,
    farmDataareaid: farm.dataareaid,
    date: GetFormattedDate(cartDate), 
    comments: cartComments!==undefined?cartComments:'', 
    status: estado, 
    products:cart,
    farm: farm,
    numberLines: cart.length,
    moveType: moveType
    }

return currentPedido
}

const calculateQuantityItems = (inputCartProducts : Array<IProduct>) => {
    return find(inputCartProducts, function(o) {return o.quantity==undefined||o.quantity==0})
}

const GetFormattedPedidoDate = (inputData: any) => {
    var todayTime = new Date(inputData);
    var month = todayTime.getMonth() + 1;
    var day = todayTime.getDate();
    var year = todayTime .getFullYear();
    return (day < 10?'0'+day:day) + "/" + (month < 10?'0'+month:month) + "/" + year;
}

const GetDatetimeFormat = (inputData: any) => {
    var todayTime = new Date(inputData);
    var month = todayTime.getMonth() + 1;
    var day = todayTime.getDate();
    var year = todayTime.getFullYear();
    var hour = todayTime.getHours();
    var minut = todayTime.getMinutes();

    return (day < 10?'0'+day:day) + "/" + (month < 10?'0'+month:month) + "/" + year + " " + (hour < 10?'0'+hour:hour)+ ":" + (minut < 10?'0'+minut:minut)+"h";
}

function addZeroesDecimals(num:number, decimals:number) {
    let roundDecimals:string = num.toFixed(decimals);
    roundDecimals = roundDecimals.replace('.',',');
    return roundDecimals;
}

function addThousandSeparator(num:string|number, comma:boolean) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, `${comma?',':'.'}`);
}

const allowAddItem=(product:IProduct, farm:IFarm, currentFeedStageIndex:number, feedPlan: IFeedPlan)=>{
    let result = undefined; // Por defecto permite pedirlo

    if(!feedPlan.continues && currentFeedStageIndex !== 99){ // Realiza el control en caso de no ser una granja de Ciclo contínuo y permite siempre coger los productos de la etapa 99

        // Primero verifica si se ha sobrepasado la desviación máxima permitida
        let qtyReserved = feedPlan.plan[currentFeedStageIndex].feed[0].qtyReserved;
        let qtyReal = feedPlan.plan[currentFeedStageIndex].feed[0].CANT_REAL;
        let qtyPrevista = feedPlan.plan[currentFeedStageIndex].feed[0].CANT_PREVISTA;
        let qtyPercentage = ((qtyReal + qtyReserved)/qtyPrevista)*100;

        let upDesv = feedPlan.plan[currentFeedStageIndex].feed[0].DESVADMITIDA + 100;

        if(qtyPercentage >= upDesv){
            result = `LÍMITE ALCANZADO`;
        }

    }
    
    if(product.MEDICADO == 1){ // Se comprueba que si el producto es medicado, el usuario pueda pedirlo para esa granja
        if(farm.allowMedicado == 0){
            result = `NO AUTORIZADO`;
        }
    }

    return result;
}

const getProductsCurrentFeedPlanStage=(products:Array<IProduct>, farm:IFarm)=>{
    let newProducts:Array<IProduct> = [];

    farm.feedPlan.map((feedPlan:IFeedPlan, idx00:number)=>{
        let currentFeedStageIndex = getCurrentFeedPlanStage(feedPlan);
        let etapa99Index = findIndex(feedPlan.plan, function(o){return o.ETAPAALIMENTACION == 99});
    
        let qtyPrevista:any = undefined;
        let qtyReal:any = undefined;
        let qtyReserved:any = undefined;
        //let upDesv:any = undefined;
        let downDesv:any = undefined;
        let qtyConsumidaPercentage:any = undefined;
        let tmpStageData:any = undefined;
    
        if(currentFeedStageIndex !== -1){
            qtyPrevista = feedPlan.plan[currentFeedStageIndex].feed[0].CANT_PREVISTA;
            qtyReal = feedPlan.plan[currentFeedStageIndex].feed[0].CANT_REAL;
            qtyReserved = feedPlan.plan[currentFeedStageIndex].feed[0].qtyReserved;
            qtyConsumidaPercentage = ((qtyReal + qtyReserved)/qtyPrevista)*100;
            //upDesv = farm.feedPlan[currentFeedStageIndex].feed[0].CANT_REAL + (qtyPrevista * (farm.feedPlan[currentFeedStageIndex].feed[0].DESVADMITIDA/100))
            downDesv = 100 - feedPlan.plan[currentFeedStageIndex].feed[0].DESVADMITIDAINF;
        }
    
    
        products.map((item:IProduct, index:number)=>{
            if(item.dataareaid == farm.dataareaid){
                if(downDesv !== undefined && qtyConsumidaPercentage !== undefined) {
                    // Revisa los productos para el feedPlan actual
                    if(feedPlan.plan[currentFeedStageIndex] !== undefined && findIndex(feedPlan.plan[currentFeedStageIndex].feed, function(o) { return o.ITEMID == item.ITEMID; }) !== -1){
    
                        tmpStageData = feedPlan.plan[currentFeedStageIndex].feed[0];
                        newProducts.push({
                            ...item,
                            allowItem:allowAddItem(item, farm, currentFeedStageIndex, feedPlan),
                            maxQty:((tmpStageData.CANT_PREVISTA + (tmpStageData.CANT_PREVISTA * (tmpStageData.DESVADMITIDA/100))) - (tmpStageData.CANT_REAL + tmpStageData.qtyReserved)),
                            continues: feedPlan.continues,
                            feedStage: currentFeedStageIndex,
                            numEtapa: feedPlan.plan[currentFeedStageIndex].ETAPAALIMENTACION,
                            batchId: feedPlan.plan[currentFeedStageIndex].feed[findIndex(feedPlan.plan[currentFeedStageIndex].feed, function(o) { return o.ITEMID == item.ITEMID; })].batchId
                        });
                    }
    
                    // Si qtyConsumidaPercentage es igual o superior a downDesv abre la siguiente etapa
                    if(feedPlan.plan[currentFeedStageIndex] !== undefined && qtyConsumidaPercentage >= downDesv && currentFeedStageIndex < (feedPlan.plan.length-1) && findIndex(feedPlan.plan[currentFeedStageIndex+1].feed, function(o) { return o.ITEMID == item.ITEMID; }) !== -1){
                        tmpStageData = feedPlan.plan[currentFeedStageIndex+1].feed[0];
                        newProducts.push({
                            ...item,
                            allowItem:allowAddItem(item, farm, currentFeedStageIndex+1, feedPlan),
                            maxQty:((tmpStageData.CANT_PREVISTA + (tmpStageData.CANT_PREVISTA * (tmpStageData.DESVADMITIDA/100))) - (tmpStageData.CANT_REAL + tmpStageData.qtyReserved)),
                            continues: feedPlan.continues,
                            feedStage: (currentFeedStageIndex + 1),
                            numEtapa: feedPlan.plan[currentFeedStageIndex + 1].ETAPAALIMENTACION,
                            batchId: feedPlan.plan[currentFeedStageIndex].feed[findIndex(feedPlan.plan[currentFeedStageIndex + 1].feed, function(o) { return o.ITEMID == item.ITEMID; })].batchId
                        });
                    }
    
                    // Si qtyConsumidaPercentage es inferior a downDesv mantiene la anterior etapa abierta
                    if(feedPlan.plan[currentFeedStageIndex] !== undefined && qtyConsumidaPercentage < downDesv  && currentFeedStageIndex > 0 && findIndex(feedPlan.plan[currentFeedStageIndex-1].feed, function(o) { return o.ITEMID == item.ITEMID; }) !== -1){
                        tmpStageData = feedPlan.plan[currentFeedStageIndex-1].feed[0];
                        newProducts.push({
                            ...item,
                            allowItem:allowAddItem(item, farm, currentFeedStageIndex-1, feedPlan),
                            maxQty:((tmpStageData.CANT_PREVISTA + (tmpStageData.CANT_PREVISTA * (tmpStageData.DESVADMITIDA/100))) - (tmpStageData.CANT_REAL + tmpStageData.qtyReserved)),
                            continues: feedPlan.continues,
                            feedStage: (currentFeedStageIndex - 1),
                            numEtapa: feedPlan.plan[currentFeedStageIndex - 1].ETAPAALIMENTACION,
                            batchId: feedPlan.plan[currentFeedStageIndex].feed[findIndex(feedPlan.plan[currentFeedStageIndex - 1].feed, function(o) { return o.ITEMID == item.ITEMID; })].batchId
                        });
                    }
    
                    // En caso de haber etapa 99 añade el producto
                    if(etapa99Index !== -1 && findIndex(feedPlan.plan[etapa99Index].feed, function(o) { return o.ITEMID == item.ITEMID; }) !== -1){
                        newProducts.push({
                            ...item,
                            allowItem:allowAddItem(item, farm, etapa99Index, feedPlan),
                            maxQty:farm.truckLoad,
                            continues: feedPlan.continues,
                            feedStage: (etapa99Index),
                            numEtapa: feedPlan.plan[etapa99Index].ETAPAALIMENTACION,
                            batchId: feedPlan.plan[etapa99Index].feed[findIndex(feedPlan.plan[etapa99Index].feed, function(o) { return o.ITEMID == item.ITEMID; })].batchId
                        });
                    }
                }
            }
        })
    })

    
    console.log(newProducts)
    return sortBy(newProducts, [function(o) { return o.numEtapa; }]);
}

const getCurrentFeedPlanStage=(feedplan:IFeedPlan)=>{
    let currentFeedStageIndex = -1;

    if(feedplan.plan.length > 0) { // Define como etapa actual la 1 por si es la primera de todas
        currentFeedStageIndex = 0;
    }
    
    for (var i = 0; i < feedplan.plan.length; i++) { // Mira la última etapa donde tiene pienso consumido
        if((feedplan.plan[i].feed[0].CANT_REAL + feedplan.plan[i].feed[0].qtyReserved) > 0 && feedplan.plan[i].ETAPAALIMENTACION != 99) // Omite la etapa 99 para que no se posicione nunca allí
            currentFeedStageIndex = i;
    }

    // Revisa si se ha sobrepasado el máximo permitido en esa estapa
    /*let desv = farm.feedPlan[currentFeedStageIndex].feed[0].CANT_PREVISTA * (farm.feedPlan[currentFeedStageIndex].feed[0].DESVADMITIDA/100)

    if(farm.feedPlan[currentFeedStageIndex].feed[0].CANT_REAL > (farm.feedPlan[currentFeedStageIndex].feed[0].CANT_PREVISTA + desv) && currentFeedStageIndex < (farm.feedPlan.length-1)){
        currentFeedStageIndex++;
    }*/

    return currentFeedStageIndex;
}

const verifyCarga = (box:number, quantity:number, truckLoad:number, forceDown?:boolean) =>{
    let quantityBox:number = Math.floor(truckLoad/box)
    let numberBox : number = forceDown?Math.floor(quantity/quantityBox):Math.round(quantity/quantityBox);
    let resultQuantity = quantity;
    if(numberBox==0){
        numberBox=1;
    }

    if(resultQuantity == ((box-numberBox)==0?truckLoad:(numberBox*quantityBox)) && resultQuantity <=truckLoad) {
        return [true, resultQuantity];
    }
    let modifiedQuantity = numberBox*quantityBox;

    if(modifiedQuantity <= truckLoad){
        return [false, ((box-numberBox)==0?truckLoad:(numberBox*quantityBox))];
    }

    return [false, truckLoad];
}

const allowAddQtySilo=(silo:ISilo, qty:number)=>{
    if(qty > silo.capacidad){
        return false;
    }
    return true;
}

const currentLoad=(products:Array<IProduct>)=>{
    let totalQty = reduce(products, function(sum, n) {
        return sum + (n.quantity==undefined?0:n.quantity);
    }, 0);
    return totalQty;
}

const allowViewSilo=(silo:ISilo, product:IProduct, type:number)=>{
    switch(type){
        case 1: // Verifica si el producto está dentro de los artículos permitidos para el silo
            if(silo.tipoPiensoSiloID.length == 0){ // Si no se ha configurado el tipo de pienso en el silo, permite asignar el producte en este
                return true;
            }
        
            let findGroup = findIndex(product.groupTipoSiloID, function(o){return o.TIPOPIENSOSILOID==silo.tipoPiensoSiloID});
        
            if(findGroup > - 1){
                return true;
            }
            return false;
        default: // Por defecto no permite ver el silo
            return false;
    }
}

const getTotalQtyStage = (cart:Array<IProduct>, product:IProduct) => {
    let totalQty = reduce(cart, function(sum, n) {
        if(n.feedStage == product.feedStage){
            if(n.ITEMID !== product.ITEMID){
                return sum + (n.quantity==undefined?0:n.quantity);
            }
        }
        return sum;
    }, 0);
    return totalQty;
}

const controlProduct=(product:IProduct, cart:Array<IProduct>, farm:IFarm, quantity:number)=>{
    let qty:number = quantity;
    let result = 0;
    let count = 0;
    let maxLoop = 2;
    let doAgain = false;
    do{
        doAgain = false;

        if(!product.continues){ // Realiza el control en caso de no ser una granja del tipo Ciclo contínuo
            let accumStage = getTotalQtyStage(cart, product);

            if((accumStage + qty) > product.maxQty){ // Si la cantidad de producto supera la cantidad total de la etapa, devuelve el error y la cantidad siempre y cuando no sean granjas de madres
                result = 3;
                qty = product.maxQty-accumStage;
            }
        }

        let load = currentLoad(cart) - (product.isInCart && product.quantity?product.quantity:0);

        let freeSpace = farm.truckLoad - load;

        if((freeSpace - qty) < 0){ // Si la carga del camión es superada, devuelve el error y la carga que debería tener
            result = 2;
            qty = freeSpace;
        }
    
        if(product.NOREDONDEARCAJON == 0){ // Se redondea el cajón
            let carga:any = verifyCarga(6, qty, farm.truckLoad, count==0?false:true);
            
            if(!carga[0]){ // Ajusta cantidad cajón
                qty = carga[1];
                doAgain = true;
                if(result == 0){ // Solo dice que ha ajustado cajón cuando no ha habido otro ajuste
                    result = 1;
                }
            }
        }
        count++;
    }   while(doAgain && count <=maxLoop)

    if(doAgain){
        result = -1;
        qty = 0;
    }
    return [result, qty];
}

const controlSiloProduct=(silo:ISilo, product:IProduct, quantity:number)=>{
    let qty:number = quantity;
    let result:number = 0;
    if(!allowAddQtySilo(silo, qty)){ // Controla si la cantidad excede la capacidad del silo
        result = 1;
        qty = silo.capacidad;
    }

    return([result, qty]);
    
}

const CheckNumberPlate=(type: number, numberplate:string)=>{
    switch(type){
        case 1: // Matrícula de camión
        return /^[0-9]{4}[aA-zZ]{3}/.test(numberplate);
        case 2: // Matrícula de remolque
            return /^[aA-zZ]{1}[0-9]{4}[aA-zZ]{3}/.test(numberplate);
        default:
            return false;
    }
}

export { fetchAPI, searchFarms, GetFormattedDate,
    GetDate, searchProducts,
    getNextOrden, getQuantitySilo, getAcumuladoPienso,
    verifyDateTimeLimit, createArrayPedido,
    calculateQuantityItems, GetFormattedPedidoDate, addZeroesDecimals,
    /*getStatusDescriptionColor,*/searchPedidos, getProductsCurrentFeedPlanStage,
    getCurrentFeedPlanStage, verifyCarga, GetDatetimeFormat,
    addThousandSeparator, allowAddQtySilo, currentLoad, allowViewSilo,
    getTotalQtyStage, controlProduct, controlSiloProduct, CheckNumberPlate
};