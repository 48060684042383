import React, { Component } from 'react';
import { IPedido, IProduct, IMe, IFarm } from '../types';
import OrderService from '../ApiClients/OrderService';
import Summary from './Summary';
import AppBar from './AppBar';
import Loader from './Loader';
import {find} from 'lodash';
import { withParamsAndNavigate } from './Material/ReactRouter';

interface IPedidoDetailProps {
    params?:any;
    navigate?:any;    
    updateCart: Function;
    resetCart: Function;
    myInfo?:IMe;
}

interface IPedidoDetailState {
    pedido?: IPedido;
    loading: boolean;
}

class ProductDetail extends Component<IPedidoDetailProps, IPedidoDetailState>  {
    constructor(props: IPedidoDetailProps) {
        super(props);
        this.state = {
            loading: true
        };
    }

    componentDidMount() {
        if(this.props.myInfo !== undefined && this.props.myInfo.supervisor){
            this.loadPedidoDetails();
        } else {
            this.props.navigate(`/`)
        }
    }

    updateCart = (inputProduct: IProduct) => {
        this.props.updateCart(inputProduct)
    }

    componentDidUpdate(prevProps: IPedidoDetailProps) {
        if (this.props.params.pedidoRef !== prevProps.params.pedidoRef || this.props.params.farmRef !== prevProps.params.farmRef || this.props.params.dataareaid !== prevProps.params.dataareaid) {
            this.loadPedidoDetails();
        }
    }

    loadPedidoDetails = async () => {
        let pedidoRef = this.props.params.pedidoRef;
        let farmRef = this.props.params.farmRef;
        let dataareaid = this.props.params.dataareaid;

        let response:Array<IFarm> = await OrderService.getPedidosRelated(false);
        let newPedidos = find(response, function (o) {return o.id == farmRef && o.dataareaid == dataareaid});
        let pedidos:Array<IPedido> = [];

        if(newPedidos !== undefined && newPedidos.pedidos !== undefined){
            pedidos = newPedidos.pedidos;
            let newPedido = find(pedidos, function (o) {return o.id == pedidoRef});

            this.setState({
                pedido: newPedido,
                loading: false
            });
            return;
        }

        this.setState({ pedido:undefined, loading: false });
    }

    render() {
        const { pedido } = this.state;
        if (pedido) {
            return (
                <Summary
                    farm={pedido.farm}
                    cart={pedido.products}
                    cartDate={pedido.date}
                    cartComments={pedido.comments}
                    pedidoId={pedido.id}
                    isPedido={true}
                    status={pedido.status}
                    pedido={pedido}
                    updateCart={this.props.updateCart}
                    resetCart={this.props.resetCart}
                    myInfo={this.props.myInfo}
                />
            );
        } else {
            return(     
                <AppBar title={'Detalles Pedido'} navigate={this.props.navigate}>
                    {this.state.loading?
                        <div style={{marginLeft:'50%',marginTop:200}}>
                        <Loader loading={this.state.loading}/>
                        </div>
                        :                
                        <div style={{textAlign: 'center', marginTop:'50%' }}>
                        <h3>{'El pedido no existe'}</h3>
                        </div>
                    }                    
                </AppBar>
            );
        }
    }
}

export default withParamsAndNavigate(ProductDetail);