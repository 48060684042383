import React, { Component } from 'react';
import AppBar from './AppBar';
import VirtualizedList from './VirtualizedList';
import { IFarm, IMe } from '../types';
import SearchField from './SearchField';
import Loader from './Loader';
import {searchFarms} from '../helpers'
import FarmListItem from './FarmListItem';
import OrderService from '../ApiClients/OrderService';
import { withParamsAndNavigate } from './Material/ReactRouter';

interface IFarmProps {
    params?:any;
    navigate?:any;    
    myInfo?:IMe;
}

interface IFarmState {
    farms?: Array<IFarm>;
    searchValue: string;
    filteredFarms: Array<IFarm>;
    loading: boolean;
}

class Farms extends Component<IFarmProps, IFarmState> {
    constructor(props: IFarmProps) {
        super(props);
        this.state = {
            farms: undefined,
            filteredFarms:[],
            searchValue: '',
            loading: true
        };
    }

    componentDidMount() {
        this.getFarms();
    }

    resetLocalStorage = () => {
        localStorage.setItem('searchValueProduct', '')
    }

    getFarms = async () =>{
        let response = await OrderService.getFarms(false);
        //console.log('farms', response);

        if(response !== undefined){
            this.setState({
                filteredFarms: searchFarms(response, this.state.searchValue),
                farms: response,
                loading:false
            })
            /*
            if(response.length === 1) {
                this.resetLocalStorage();
                this.props.history.push(`/`)
                this.props.history.push(`/granja/${response[0].dataareaid}/${response[0].id}/productos`)
            } else{
                this.setState({
                    filteredFarms: searchFarms(response, this.state.searchValue),
                    farms: response,
                    loading:false
                })
            }*/
        }
    }

    renderFarms=()=>{
        if(this.state.loading){
            return(
                <div style={{marginLeft:'50%',marginTop:200}}>
                    <Loader loading={true}/>
                </div>
            );
        }
        else {
            const {filteredFarms} = this.state;
            if(filteredFarms.length == 0)
                return (
                    <div style={{textAlign:'center', padding:50}}>
                        {this.state.searchValue?.length == 0?
                            <p>
                                {`No tiene granjas configuradas para visualizar.`}
                            </p>
                        :
                            <p>
                                {`No existe ninguna granja con el valor de búsqueda aplicado.`}
                            </p>
                        }
                    </div>
                )
            let items = filteredFarms.map((farm) => {
                return {
                    ...farm,
                    onItemClick: () => this.handleItemClick(farm),
                    onInfoClick: () => this.handleInfoClick(farm),
                }
            })
            return(
                <VirtualizedList items={items} RowComponent={FarmListItem} itemSize={130} />
            );
        }
    }

    handleItemClick = (farm: IFarm) => {
        this.resetLocalStorage();
        this.props.navigate(`/granja/${farm.dataareaid}/${farm.id}/productos`)
    }

    handleInfoClick = (farm: IFarm) => {
        this.props.navigate(`/granja/${farm.dataareaid}/${farm.id}`)
    }

    handleValueChange = (evt: any) => {
        if(this.state.farms !== undefined) {
            this.setState({ 
                searchValue: evt.target.value,
                filteredFarms: searchFarms(this.state.farms, evt.target.value)
             })
        }
    }

    render() {

        return (
            <AppBar title={'Mis Granjas'} navigate={this.props.navigate}>
                <div style={{ marginTop: 15 }}>
                    <SearchField allowSearch={this.state.loading} value={this.state.searchValue} onValueChange={this.handleValueChange} placeholder="Buscar granja..." />
                </div>
                <div style={{
                    position: 'absolute',
                    top: '65px',
                    left: 0,
                    right: 0,
                    bottom: 0,
                }}>
                {this.renderFarms()}
                </div>
            </AppBar>
        );
    }
}
export default withParamsAndNavigate(Farms);