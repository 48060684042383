import React, { Component } from 'react';
import { IMe, IFarm, INave, ISilo, IFeedPlan, IFeed, IIFeedPlan } from '../types';
import { createStyles, withStyles, Theme } from '@material-ui/core/styles';
import AppBar from './AppBar';
import OrderService from '../ApiClients/OrderService';
import Loader from './Loader';
import { addZeroesDecimals, getCurrentFeedPlanStage, addThousandSeparator } from '../helpers'
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import './ElementContainer.css';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Paper from '@material-ui/core/Paper';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { withParamsAndNavigate } from './Material/ReactRouter';

const styles = (theme: Theme) => createStyles({
    container: {
        padding: '10px',
        textAlign: 'center',
        overflowY:'auto'
    },
    image: {
        width: '100%',
        height: 'auto'
    },
    promotion: {
        //backgroundColor: '#f00',
        padding: '3px 15px',
        //color: '#fff',
        verticalAlign: 'middle',
    },
    root: {
    width: '100%',
    },
    paper: {
        width: '100%',
        marginTop: '30px',
        overflowX: 'auto',
    },
    heading: {
    fontSize: theme.typography.pxToRem(15),
    // fontWeight: theme.typography.fontWeightRegular,
    },
    item: {
        maxWidth:'200px',
        border: '2px solid #ccc',
        borderRadius: '15px',
        margin: '5px 10px',
        width: 'calc(100% - 20px)!important',
        boxShadow: '5px 5px 5px 0px rgba(0,0,0,0.50)',
        cursor: 'pointer',
        paddingTop: '10px',
        paddingBottom: '10px',
        position: 'relative',
    },
});

const getFeedColor=(etapa:IIFeedPlan, item:IIFeedPlan)=>{
    //let desv = etapa.feed[0].CANT_PREVISTA * (etapa.feed[0].DESVADMITIDA/100)
    //let upDesv = etapa.feed[0].CANT_REAL + desv
    //let downDesv = etapa.feed[0].CANT_REAL - desv
    let downDesv = 100 - etapa.feed[0].DESVADMITIDAINF
    let qtyConsumidaPercentage = ((etapa.feed[0].CANT_REAL + etapa.feed[0].qtyReserved)/etapa.feed[0].CANT_PREVISTA)*100;

    if(item.ETAPAALIMENTACION == (etapa.ETAPAALIMENTACION-1) && qtyConsumidaPercentage < downDesv){
        return('#b3e6b3')
    }

    if(item.ETAPAALIMENTACION == (etapa.ETAPAALIMENTACION+1) && qtyConsumidaPercentage >= downDesv){
        return('#267326')

    }

    if(etapa.ETAPAALIMENTACION == item.ETAPAALIMENTACION){
        return('#39ac39')
    }
    return '#FFFFFF'
}

interface IFarmDetailProps {
    params?:any;
    navigate?:any;    
    classes: any;
    myInfo?:IMe;
}

interface IFarmDetailState {
    farm?: IFarm;
    loading: boolean;
    toggled: boolean;
    etapa?:IIFeedPlan;
}

class FarmDetail extends Component<IFarmDetailProps, IFarmDetailState>  {
    constructor(props: IFarmDetailProps) {
        super(props);
        this.state = {
            loading: true,
            toggled:false
        };
    }

    componentDidMount() {
        this.loadFarmDetail();
    }

    componentDidUpdate(prevProps: IFarmDetailProps) {
        if (this.props.params.farmRef !== prevProps.params.farmRef || this.props.params.dataareaid !== prevProps.params.dataareaid) {
            this.loadFarmDetail();
        }
    }

    loadFarmDetail = async () => {
        let farmRef = this.props.params.farmRef;
        let dataareaid = this.props.params.dataareaid;

        let response:IFarm = await OrderService.getFarm(farmRef, dataareaid);

        // if(response !== undefined){
        //     let index = response.feedPlan[getCurrentFeedPlanStage(response)];
        //     if(index !== undefined){
        //         this.setState({etapa: response.feedPlan[getCurrentFeedPlanStage(response)]});
        //     }
        // }

        this.setState({farm:response, loading:false });
    }

    loadBuildingData=(farm:IFarm)=>{
        let propagateItems = farm.building.map((nave:INave, index:number)=>{
            let silos = nave.silo.map((silo:ISilo, index:number)=>{
                return(
                    <ExpansionPanelDetails key={index}>
                        <div className={this.props.classes.item} style={{margin:'auto', display:'block'}}>
                            {'Silo: '}{silo.SILOID}
                            <br/>
                            {`Capacidad: ${silo.capacidad} ${silo.ud}`}
                        </div>
                    </ExpansionPanelDetails>
                );
            });

            return (
                <ExpansionPanel key={index}>
                    <ExpansionPanelSummary key={index} expandIcon={<ExpandMoreIcon /> }>
                        <strong style={{ width: '100%', position: 'relative'}}>
                            {'Nave: '}{nave.NAVEID}
                        </strong>
                    </ExpansionPanelSummary>
                    {silos}
                </ExpansionPanel>
            );
        });
        return propagateItems;
    }

    loadFeedPlan=(farm:IFarm)=>{
        let propagateFeedPlan = farm.feedPlan.map((item:IFeedPlan, index:number)=>{
            let feedPlan = 
                item.plan.map((item01:IIFeedPlan, index01:number)=>{
                    let idxFeedStage = getCurrentFeedPlanStage(item);
                    let etapa = undefined;

                    if(idxFeedStage > -1)
                        etapa = item.plan[getCurrentFeedPlanStage(item)];

                    let plan = <div>
                        <Paper className={this.props.classes.paper}>
                            <Table className={this.props.classes.table} aria-label="simple table">
                                <TableHead>
                                <TableRow>
                                <TableCell align="center">{`Producto`}</TableCell>
                                <TableCell align="center">{`Consumido`}</TableCell>
                                <TableCell align="center">{`Reservado`}</TableCell>
                                <TableCell align="center">{`Previsto`}</TableCell>
                                <TableCell align="center">{`Restante`}</TableCell>
                                {/*<TableCell align="center">{`Tipo`}</TableCell>*/}
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                {item01.feed.map((result:IFeed, index:number) => (
                                <TableRow key={index}>
                                <TableCell align="center">
                                {result.ITEMNAME}
                                </TableCell>
                                <TableCell style={{whiteSpace:'nowrap'}} align="center">{`${addThousandSeparator(addZeroesDecimals((result.CANT_REAL),0),false)} ${result.unitId}`}</TableCell>
                                <TableCell style={{whiteSpace:'nowrap'}} align="center">{`${addThousandSeparator(addZeroesDecimals((result.qtyReserved),0),false)} ${result.unitId}`}</TableCell>
                                <TableCell style={{whiteSpace:'nowrap'}} align="center">{`${addThousandSeparator(addZeroesDecimals(result.CANT_PREVISTA,0),false)} ${result.unitId}`}</TableCell>
                                <TableCell style={{whiteSpace:'nowrap'}} align="center">{`${addThousandSeparator(addZeroesDecimals(result.CANT_PREVISTA - (result.CANT_REAL + result.qtyReserved),0),false)} ${result.unitId}`}</TableCell>
                                {/*<TableCell align="center">{result.IMPORTANCIA==0?'P':'S'}</TableCell>*/}
                                </TableRow>
                                ))}
                                </TableBody>
                            </Table>
                        </Paper>
                    </div>

                    return(
                        <ExpansionPanel key={index}>
                            <ExpansionPanelSummary style={{backgroundColor:etapa?getFeedColor(etapa,item01):'#FFFFFF'}} key={index} expandIcon={<ExpandMoreIcon /> }>
                                <strong style={{ width: '100%', position: 'relative'}}>
                                    {'ETAPA: '}{item01.ETAPAALIMENTACION}
                                </strong>
                            </ExpansionPanelSummary>
                            {plan}
                        </ExpansionPanel>
                    )
                })
            

            return (
                <ExpansionPanel key={index} style={{marginBottom:20, border: '1px solid #ddd'}}>
                    <ExpansionPanelSummary key={index} expandIcon={<ExpandMoreIcon /> }>
                        <div style={{whiteSpace:'wrap', textAlign: 'center', width: '100%'}}>
                            <div>
                                <strong style={{ width: '100%', position: 'relative'}}>
                                    {'LOTE'}
                                </strong>
                            </div>
                            <div>
                                <strong style={{ width: '100%', position: 'relative'}}>
                                    {item.batchId}
                                </strong>
                            </div> 
                        </div>
                    </ExpansionPanelSummary>
                    {feedPlan}
                </ExpansionPanel>
            )
        });
        return propagateFeedPlan;
    }
    
    render() {
        const { classes } = this.props;

        const { farm } = this.state;

        return (
            <AppBar title={'Detalle Granja'} navigate={this.props.navigate} >
                {farm !== undefined ?
                    <div className={classes.container}>
                        <div style={{marginTop:30, marginBottom:30}}>
                        <Paper>
                            <Table className={classes.table} aria-label="simple table">
                                <TableBody>
                                <TableRow key={1}>
                                <TableCell align="left"><strong>{`ID Granja`}</strong></TableCell>
                                <TableCell align="center">{farm.id}</TableCell>
                                </TableRow>
                                <TableRow key={2}>
                                <TableCell align="left"><strong>{`Nombre`}</strong></TableCell>
                                <TableCell align="center">{farm.name}</TableCell>
                                </TableRow>
                                <TableRow key={3}>
                                <TableCell align="left"><strong>{`Lotes activos`}</strong></TableCell>
                                <TableCell align="center">
                                    <label>
                                        {farm.feedPlan.length == 0?
                                            `Sin lotes activos`
                                        :
                                            farm.feedPlan.map((feedplan:IFeedPlan, idx00:number)=>{
                                                return <p key={idx00}>{feedplan.batchId}</p>
                                            })
                                        }
                                    </label>
                                </TableCell> 

                                {/* <TableCell align="center">{`${farm.batchId==null?`Sin especificar`:farm.batchId}`}</TableCell> */}
                                </TableRow>
                                </TableBody>
                            </Table>
                        </Paper>
                        </div>

                        <div>
                            <div style={{marginBottom:20}}>
                                <strong>{`Información Nave/Silo`}</strong>
                            </div>
                            {this.loadBuildingData(farm)}
                        </div>

                        <div>
                            <div style={{marginBottom:20, marginTop:20}}>
                                    <strong>{`Plan alimentación`}</strong>
                            </div>
                            {this.loadFeedPlan(farm)}
                        </div>
                        
                    </div>
                    :
                    this.state.loading ?
                        <div style={{marginLeft:'50%',marginTop:200}}>
                            <Loader loading={this.state.loading}/>
                        </div>
                    :
                    <div className={classes.container}>
                        <h3>{'Información de granja no disponible'}</h3>
                    </div>
                }
            </AppBar>
        );
    }
}
export default withParamsAndNavigate(withStyles(styles)(FarmDetail));