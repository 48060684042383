import { fetchAPI } from '../helpers';
import { IFarm, IProduct, IPedido } from '../types';
import appData from './appData';

let baseURL = 'https://fnc-nealia-feed-01.azurewebsites.net/api/';

class OrderService {
    
    static getFarms = async (refresh:boolean) => {
        try{
            let url = `${baseURL}loadFarms`
            if(appData.getListFarms() == undefined || refresh) {
                let response = await fetchAPI(url, 'GET', true, undefined, false);
                appData.setListFarms(response);
            }
            return appData.getListFarms();
        }
        catch(err){
            appData.setListFarms(undefined);
            return [];
        }
    }

    static getFarm = async (farmRef: string, dataareaid:string) => {
        if(appData.getListFarms() === undefined){
            await OrderService.getFarms(false)
        }
        
        let farm = appData.getListFarms().find((currFarm:IFarm) => {
            return currFarm.id === farmRef && currFarm.dataareaid === dataareaid;
        });
        return farm;
    }   

    static getProducts = async (refresh:boolean) => {
        try{
            let url = `${baseURL}loadProducts`
            if(appData.getListProducts() == undefined || refresh) {
                let response = await fetchAPI(url, 'GET', true, undefined, false);
                appData.setListProducts(response);
            }
            return appData.getListProducts();
        }
        catch(err){
            appData.setListProducts(undefined);
            return [];
        }
    }

    static getProduct = async (productRef: string, dataareaid:string) => {
        if(appData.getListProducts() === undefined){
            await OrderService.getProducts(false)
        }
        let product = appData.getListProducts().find((currProduct:IProduct) => {
            return currProduct.ITEMID === productRef && currProduct.dataareaid === dataareaid;
        });
        return product;
    }   
    
    static getPedidos = async (refresh:boolean, type: number) => {
        try{
            let url = `${baseURL}loadPedidos?type=${type}`

            if(appData.getListPedidos() == undefined || refresh) {
                let response = await fetchAPI(url, 'GET', true, undefined, false);
                appData.setListPedidos(response);
            }
            return appData.getListPedidos();
        }
        catch(err){
            appData.setListPedidos(undefined);
            return [];
        }
    }

    static getPedido = async (business: string, id: number, type: number) => {
        if(appData.getListPedidos() === undefined){
            await OrderService.getPedidos(false, type)
        }
        let pedido = appData.getListPedidos().find((currPedido:IPedido) => {
            if(currPedido.id){
                return currPedido.farmDataareaid == business && currPedido.id == id;
            }
        });
        return pedido;
    }   

    static getPedidosRelated = async (refresh:boolean) => {
        try{
            let url = `${baseURL}loadRelatedPedidos`

            if(appData.getListPedidosRelated() == undefined || refresh || appData.getListPedidosRelated().length == 0) {
                let response = await fetchAPI(url, 'GET', true, undefined, false);
                appData.setListPedidosRelated(response);
            }
            return appData.getListPedidosRelated();
        }
        catch(err){
            appData.setListPedidosRelated(undefined);
            return [];
        }
    }

    static postDelivery = async (pedido:IPedido) => {
        try{
            let url = `${baseURL}saveDelivery`
            let response = await fetchAPI(url, 'POST', true, {...pedido}, false);
            return response;
        }
        catch(err){
            return -1;
        }
    }

    static saveData = async (pedido:IPedido, action: number) => {
        try{
            let url = `${baseURL}saveData?action=${action}`
            let response = await fetchAPI(url, 'POST', true, {...pedido}, false);
            return response;
        }
        catch(err){
            return -1;
        }
    }

    static me = async () => {
        try{
            let url = `${baseURL}me`
            let response = await fetchAPI(url, 'GET', true, undefined, false);
            //console.log(response)
            return response;
        }
        catch(err){
            return null;
        }
    }
    
    static metricService = async (metricType:number) => { // Llamadas de registro de métricas //
        try {
            let url = `${baseURL}metrics?metricType=${metricType}&origin=${window.location.href}`
            await fetchAPI(url, 'PUT', true, undefined, false);
        } catch(err) {
        }
    }
}
export default OrderService;