import React from 'react';
import { IPedidoTrazabilidad } from '../types';
import PropTypes from 'prop-types';
import { createStyles, makeStyles, withStyles } from '@material-ui/core/styles';
import { ListChildComponentProps } from 'react-window';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { GetDatetimeFormat } from '../helpers'
import StepConnector from '@material-ui/core/StepConnector';
import AcceptedIcon from '@material-ui/icons/CheckCircleOutline';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import PendingIcon from '@material-ui/icons/AccessTime';
import RecieveIcon from '@material-ui/icons/SystemUpdate';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import AlertIcon from '@material-ui/icons/Warning';
import clsx from 'clsx';
import {findIndex} from 'lodash';

const useStyles = makeStyles(createStyles({
    root: {
        borderRadius: 0,
    },
    item: {
        borderRadius: '15px',
        margin: '5px 10px',
        width: 'calc(100% - 20px)!important',
        boxShadow: '5px 5px 5px 0px rgba(0,0,0,0.31)',
        cursor: 'pointer',
        position: 'relative',
        color: '#284770',
    },
    status: {
        position: 'absolute',
        bottom: '15px',
        right: '25px',
        borderRadius: '10px',
        padding: '5px 10px',
        fontSize: '11px',
    }
}));

const ColorlibConnector = withStyles({
    alternativeLabel: {
      top: 22,
    },
    active: {
      '& $line': {
        backgroundImage:
          'linear-gradient( 90deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
      },
    },
    completed: {
      '& $line': {
        backgroundImage:
          'linear-gradient( 90deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
      },
    },
    line: {
      width: 10,
      marginTop:5,
      height:30,
      border: 0,
      backgroundColor: '#eaeaf0',
      borderRadius: 1,
    },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
    root: {
      backgroundColor: '#ccc',
      zIndex: 1,
      color: '#fff',
      width: 50,
      height: 50,
      display: 'flex',
      borderRadius: '50%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    /*active: {
    //  backgroundImage:
    //    'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    //  boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    //},
    backgroundImage:
        `linear-gradient( 136deg, rgb(255,0,0) 0%, rgb(255,0,0) 50%, rgb(255,0,0) 100%)`,
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    },*/
    active: (props:any) => ({
      backgroundImage:
        props.style,
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    completed: (props:any) => ({
      backgroundImage:
      props.style,
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
});

function ColorlibStepIcon(props:any, color:string, estado:number) {
    const classes = useColorlibStepIconStyles({style: `linear-gradient( 136deg, ${color} 0%, ${color} 50%, ${color} 100%)`});
    const { active, completed } = props;

    const icons:any = {
      10: <PendingIcon />,
      20: <RecieveIcon />,
      30: <AcceptedIcon />,
      40: <LocalShippingIcon />,
      50: <DoneAllIcon />,
    };
  
    if(estado == 98 || estado == 99) {
      return (
        <div
        >
          <AlertIcon color={"error"} />
        </div>
      );
    }
    return (
      <div
        className={clsx(classes.root, {
          [classes.active]: active,
          [classes.completed]: completed,
        })}
      >
        {icons[String(estado)]}
      </div>
    );
  }
  
  ColorlibStepIcon.propTypes = {
    active: PropTypes.bool,
    completed: PropTypes.bool,
    icon: PropTypes.node,
};

const PedidoStepper = (props: any) => {
    const { index, style, data } = props;
    //const classes = useStyles();

    let steps:Array<IPedidoTrazabilidad> = [
      {status:10, statusDsc: '', statusColor: '', fecha:''},
      {status:20, statusDsc: '', statusColor: '', fecha:''},
      {status:30, statusDsc: '', statusColor: '', fecha:''},
      {status:40, statusDsc: '', statusColor: '', fecha:''},
      {status:50, statusDsc: '', statusColor: '', fecha:''}
    ];

    let currentStep:number=-1;

    //let items: Array<IPedidoTrazabilidad> = [{estado:10, fecha:'01/03/2020 8:15h'},{estado:20, fecha:'02/03/2020 15:31h'},{estado:30, fecha:'02/03/2020 17:14h'},{estado:98, fecha:'03/03/2020 07:05h'}];
    let items: Array<IPedidoTrazabilidad> = data;

    items.map((item:IPedidoTrazabilidad)=>{
      let cIndex = findIndex(steps, function(o) { return o.status == item.status; });

      if(cIndex !== -1){
        steps[cIndex].statusDsc = item.statusDsc;
        steps[cIndex].statusColor = item.statusColor;
        steps[cIndex].fecha=GetDatetimeFormat(item.fecha);
      }

      if(currentStep < cIndex)
        currentStep = cIndex;

      if([98,99].includes(item.status)){
        steps.splice(
          currentStep + 1,
          0,
          {
            status:item.status,
            statusDsc: item.statusDsc,
            statusColor: item.statusColor,
            fecha:GetDatetimeFormat(item.fecha)
          }
        );
        currentStep += 1;
      }
    })

    return (
        <div style={style} key={index}>
            <Stepper activeStep={currentStep} connector={<ColorlibConnector />} orientation={'vertical'}>
                {steps.map((item:IPedidoTrazabilidad, index:number) => (
                <Step key={index}>
                    <StepLabel StepIconComponent={(props)=>ColorlibStepIcon(props, item.statusColor, item.status)}>
                      <div>
                        <strong>{item.statusDsc}</strong>
                        <br/>
                        <label style={{fontSize:'11px'}}>{item.fecha}</label>
                      </div>
                    </StepLabel>
                </Step>
                ))}
            </Stepper>
        </div>
    );
}

PedidoStepper.propTypes = {
    index: PropTypes.number,
    style: PropTypes.object,
} as any;

export default PedidoStepper;