import React from 'react';
import { IIPedido, IPedido } from '../types';
import PropTypes from 'prop-types';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { ListChildComponentProps } from 'react-window';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/ArrowForwardIos';
import { GetFormattedPedidoDate } from '../helpers'

const useStyles = makeStyles(createStyles({
    root: {
        borderRadius: 0,
    },
    item: {
        borderRadius: '15px',
        margin: '5px 10px',
        width: 'calc(100% - 20px)!important',
        boxShadow: '5px 5px 5px 0px rgba(0,0,0,0.31)',
        border: '2px solid #ccc',
        cursor: 'pointer',
        position: 'relative',
        color: '#284770',
    },
    status: {
        position: 'absolute',
        bottom: '15px',
        right: '25px',
        borderRadius: '10px',
        padding: '5px 10px',
        fontSize: '11px',
    }
}));

const PedidoListItem = (props: any) => {
    const { index, style, data } = props;
    const classes = useStyles();
    let item: IIPedido = data[index];
    
    return (
        <div style={style} key={index}>
            <ListItem button classes={{ root: classes.item }} style={{borderColor: item.orderColor}} onClick={item.onItemClick}>
                {item.viewType == 2?
                    <IconButton style={{position:'absolute', top:0, right: 10}} edge="end" aria-label="Comments" onClick={item.onItemEditClick}>
                        <EditIcon />
                    </IconButton>
                :
                    null
                }
                <ListItemText style={{whiteSpace:'nowrap', overflowX:'auto'}} primary={
                    item.farm ? 
                        <React.Fragment>
                            {item.farm.name }
                        </React.Fragment>
                    : 
                        ''
                
                    } secondary={
                        item.viewType == 1?
                            <span>
                                {'Empresa: '}{item.farmDataareaid}
                                <br />
                                {'Fecha entrega: '}{GetFormattedPedidoDate(item.date)}
                                <br />
                                {'Num. Pedido: '}{item.idPedido}
                                <br />
                            </span>
                        :
                            <span>
                                {'Empresa: '}{item.farmDataareaid}
                                <br />
                                {'Fábrica: '}{item.factoryName}
                                <br />
                                {'Fecha entrega: '}{GetFormattedPedidoDate(item.date)}
                                <br />
                                {'Orden entrega: '}{item.deliveryPriority}
                                <br />
                                {'Num. Pedido: '}{item.idPedido}
                                <br />
                            </span>
                } />
                <div style={{backgroundColor:item.statusColor, color:"#ffffff"}} className={classes.status}>{item.statusDsc}</div>
                <ListItemSecondaryAction>
                    {/* <IconButton edge="end" aria-label="Comments" onClick={item.onItemClick}>
                        <DeleteIcon />
                    </IconButton> */}
                </ListItemSecondaryAction>
            </ListItem>
        </div>
    );
}

PedidoListItem.propTypes = {
    index: PropTypes.number,
    style: PropTypes.object,
} as any;

export default PedidoListItem;