import React from 'react';
import { IFarm } from '../types';
import PropTypes from 'prop-types';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { ListChildComponentProps } from 'react-window';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import ArrowIcon from '@material-ui/icons/ArrowForwardIos';
import farmIcon from '../Assets/farm.png';
import InfoIcon from '@material-ui/icons/InfoOutlined';

const useStyles = makeStyles(createStyles({
    img: {
        objectFit: 'contain',
        width:40,
        height:40
    },
    root: {
        borderRadius: 0,
    },
    item: {
        // border: '1px solid #ccc',
        borderRadius: '15px',
        margin: '5px 10px',
        width: 'calc(100% - 20px)!important',
        boxShadow: '5px 5px 5px 0px rgba(0,0,0,0.31)',
        border: '1px solid #ccc',
        cursor: 'pointer',
        paddingBottom: '50px',
        position: 'relative',
    },
    bottomActionsContainer: {
        position: 'absolute',
        bottom: 0,
        left: 15,
        right: 0,
        height: '30px',
        borderTop: '1px solid rgba(0,0,0,0.31)',
        borderBottomLeftRadius: '15px',
        borderBottomRightRadius: '15px',
        padding: '5px 0px',
        color: '#284770',
    },
    icon: {
        paddingTop: '3px',
        paddingBottom: '3px',
        color: '#284770',
        verticalAlign: 'middle',
    },
    bottomRightActionsContainer: {
        position: 'absolute',
        right: '0px',
        top: '0px',
        bottom: '0px',
        zIndex: 1,
        fontSize:'12px'
    },
    promotion: {
        //backgroundColor: '#f00',
        display: 'inline-block',
        padding: '3px 15px',
        //color: '#fff',
        verticalAlign: 'middle',
    },
    separator: {
        display: 'inline-block',
        borderRight: '1px solid rgba(0,0,0,0.31)',
        verticalAlign: 'middle',
        height: '100%',
        marginLeft: '15px'
    },
    textField: {
        marginLeft: '10px',
        marginRight: '10px',
        width: 60,
    },
    mainHeader: {
        whiteSpace:'nowrap',
        overflowX:'auto'
    }
}));

const FarmListItem = (props: any) => {
    const { index, style, data } = props;
    const classes = useStyles();
    let item: IFarm = data[index];

    return (
        <div style={style} key={index}>
            <ListItem classes={{ root: classes.item }}>
                <ListItemIcon>
                    <Avatar alt="Farm Icon" src={farmIcon} classes={{
                        root: classes.root,
                        img: classes.img
                    }} />
                </ListItemIcon>
                <ListItemText onClick={item.onItemClick} primary={item.name} secondary={`${item.id} (${item.dataareaid})`} className={classes.mainHeader} /*secondary={`Precio: ${item.price} €/${item.saleUnit}`}*/ />
                <div className={classes.bottomActionsContainer}>
                    {/*`Ocupación: ${item.ocupacion} animales`*/}
                    <div  className={classes.bottomRightActionsContainer}>
                        <div className={classes.separator} />
                        <IconButton onClick={item.onInfoClick} className={classes.icon}>
                            <InfoIcon/>
                        </IconButton>
                    </div>
                </div>
                <ListItemSecondaryAction style={{ paddingBottom: '40px' }}>
                    <IconButton edge="end" aria-label="Comments" onClick={item.onItemClick}>
                        <ArrowIcon />
                    </IconButton>
                </ListItemSecondaryAction>
            </ListItem>
        </div>
    );
}

FarmListItem.propTypes = {
    index: PropTypes.number,
    style: PropTypes.object,
} as any;

export default FarmListItem;