import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles(
    createStyles({
        root: {
            padding: '2px 4px',
            display: 'flex',
            alignItems: 'center',
            width: 'calc(100% - 20px)',
            margin: 'auto',
        },
        input: {
            marginLeft: '10px',
            flex: 1,
        },
        iconButton: {
            padding: 10,
        },
        divider: {
            width: 1,
            height: 28,
            margin: 4,
        },
    }),
);

interface ISearchFieldProps {
    value: string;
    onValueChange: any;
    placeholder: string;
    allowSearch: boolean;
}

export default function SearchField(props: ISearchFieldProps) {
    const classes = useStyles();

    return (
        <Paper className={classes.root}>
            <InputBase
                value={props.value}
                onChange={props.onValueChange}
                className={classes.input}
                placeholder={props.placeholder}
                inputProps={{ 'aria-label': props.placeholder }}
                disabled={props.allowSearch}
            />
            <IconButton className={classes.iconButton} aria-label="Buscar">
                <SearchIcon />
            </IconButton>
        </Paper>
    );
}