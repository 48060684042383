import * as React from 'react';
import './Loader.css';

import loaderImage from '../Assets/fa-refresh.svg';

interface ILoaderProps {
    loading: boolean;
};

class Loader extends React.Component<ILoaderProps, any> {
    public render(): React.ReactElement<ILoaderProps> {
        if (this.props.loading) {
            return (
                <div className='pdc-general-loader' >
                    <img src={loaderImage} width='15px' height='15px' />
                </div>
            );
        } else {
            return (<div />);
        }
    }
}


export default Loader;