import React, { Component } from 'react';
import { IPedido, IProduct, IMe } from '../types';
import OrderService from '../ApiClients/OrderService';
import Summary from './Summary';
import AppBar from './AppBar';
import Loader from './Loader';
import { withParamsAndNavigate } from './Material/ReactRouter';

interface IPedidoDetailProps {
    params?:any;
    navigate?:any;    
    updateCart: Function;
    resetCart: Function;
    myInfo?:IMe;
}

interface IPedidoDetailState {
    pedido?: IPedido;
    loading: boolean;
    type: number;
}

class ProductDetail extends Component<IPedidoDetailProps, IPedidoDetailState>  {
    constructor(props: IPedidoDetailProps) {
        super(props);
        this.state = {
            loading: true,
            type: (window.location.hash.includes("#/delivery")?2:1)
        };
    }

    componentDidMount() {
        this.loadPedidoDetails();
    }

    updateCart = (inputProduct: IProduct) => {
        this.props.updateCart(inputProduct)
    }

    componentDidUpdate(prevProps: IPedidoDetailProps) {
        if (this.props.params.pedidoRef !== prevProps.params.pedidoRef) {
            this.loadPedidoDetails();
        }
    }

    loadPedidoDetails = async () => {
        // let type = 0;
        // if(window.location.hash.includes("#/pedidos"))
        //     type = 1;
        // else if(window.location.hash.includes("#/delivery"))
        //     type = 2;

        let business = this.props.params.dataareaid;
        let pedidoRef = this.props.params.pedidoRef;
        let pedido = await OrderService.getPedido(business, pedidoRef, this.state.type);

        this.setState({ pedido, loading: false });
    }

    render() {
        const { pedido } = this.state;
        console.log(pedido)
        if (pedido) {
            return (
                <Summary
                    farm={pedido.farm}
                    cart={pedido.products}
                    cartDate={pedido.date}
                    cartComments={pedido.comments}
                    pedidoId={pedido.id}
                    isPedido={true}
                    status={pedido.status}
                    pedido={pedido}
                    updateCart={this.props.updateCart}
                    resetCart={this.props.resetCart}
                    myInfo={this.props.myInfo}
                    type={this.state.type}
                />
            );
        } else {
            return(     
                <AppBar title={'Detalles Pedido'} navigate={this.props.navigate}>
                    {this.state.loading?
                        <div style={{marginLeft:'50%',marginTop:200}}>
                        <Loader loading={this.state.loading}/>
                        </div>
                        :                
                        <div style={{textAlign: 'center', marginTop:'50%' }}>
                        <h3>{'El pedido no existe'}</h3>
                        </div>
                    }                    
                </AppBar>
            );
        }
    }
}

export default withParamsAndNavigate(ProductDetail);