import React, { Component } from 'react';
import AppBar from './AppBar';
import VirtualizedList from './VirtualizedList';
import { IFarm, IMe, IIPedido } from '../types';
import SearchField from './SearchField';
import OrderService from '../ApiClients/OrderService';
import PedidoListItem from './PedidoListItem';
import Loader from './Loader';
import { searchPedidos } from '../helpers';
import {find} from 'lodash';
import { withParamsAndNavigate } from './Material/ReactRouter';

interface IReviewPedidosProps {
    params?:any;
    navigate?:any;    
    myInfo?: IMe;
}

interface IReviewPedidosState {
    pedidos: Array<IIPedido>;
    searchValue: string;
    loading: boolean;
    filteredPedidos: Array<IIPedido>
}

class ReviewPedidos extends Component<IReviewPedidosProps, IReviewPedidosState> {
    constructor(props: IReviewPedidosProps) {
        super(props);
        this.state = {
            pedidos: [],
            searchValue: '',
            loading: true,
            filteredPedidos:[]
        };
    }

    componentDidMount() {
        if(this.props.myInfo !== undefined && this.props.myInfo.supervisor){
            this.loadPedidos();
        } else {
            this.props.navigate(`/`)
        }
    }

    loadPedidos = async () => {
        let granja = this.props.params.farmRef;
        let dataareaid = this.props.params.dataareaid;

        let response:Array<IFarm> = await OrderService.getPedidosRelated(false);
        let newPedidos = find(response, function (o) {return o.id == granja && o.dataareaid == dataareaid});
        let pedidos:Array<IIPedido> = [];
        if(newPedidos !== undefined && newPedidos.pedidos !== undefined){
            pedidos = newPedidos.pedidos;

            this.setState({
                pedidos,
                filteredPedidos: searchPedidos(pedidos, this.state.searchValue),
                loading: false
            });
            return;
        }
        
        this.setState({
            pedidos,
            filteredPedidos: searchPedidos(pedidos, this.state.searchValue),
            loading: false
        });
    }

    handleValueChange = (evt: any) => {
        this.setState({ 
            searchValue: evt.target.value,
            filteredPedidos: searchPedidos(this.state.pedidos, evt.target.value)
        })
    }

    handleItemClick = (pedido: IIPedido, i: number) => {
        this.props.navigate(`/pedidos/${pedido.farmDataareaid}/${pedido.farmId}/${pedido.id}`)
    }

    renderPedidos=()=>{
        if(this.state.loading){
            return(
                <div style={{marginLeft:'50%',marginTop:200}}>
                    <Loader loading={true}/>
                </div>
            );
        }
        else {
            let items = this.state.filteredPedidos.map((pedido, i) => {
                return {
                    ...pedido,
                    onItemClick: () => this.handleItemClick(pedido, i),
                }
            })
            return(
                <VirtualizedList items={items} RowComponent={PedidoListItem} itemSize={150} />
            );
        }
    }

    render() {

        return (
            <AppBar title={'Supervisión'} navigate={this.props.navigate}>
                <div style={{ paddingTop: '10px' }}>
                    <SearchField allowSearch={this.state.loading} value={this.state.searchValue} onValueChange={this.handleValueChange} placeholder="Buscar pedido..." />
                </div>
                <div style={{
                    position: 'absolute',
                    top: 70,
                    left: 0,
                    right: 0,
                    bottom: 0,
                }}>
                {this.renderPedidos()}
                </div>
            </AppBar>
        );
    }
}
export default withParamsAndNavigate(ReviewPedidos);