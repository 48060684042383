import React, { Component } from 'react';
import { createStyles, withStyles, Theme } from '@material-ui/core/styles';
import { IMe } from '../types';
import { withParamsAndNavigate } from './Material/ReactRouter';

const styles = (theme: Theme) => createStyles({
    containerStyle: {
        textAlign: 'center',
        verticalAlign: 'middle',
        color: '#888',
        lineHeight: '1.2',
        marginTop: '40vh',
    },
    titleStyle: {
        color: '#555',
        fontSize: '2em',
        fontWeight: 'bold',
    },
    bodyStyle: {
        margin: '0 auto',
        width: '280px',
    },
    imageStyle: {
        width: 200,
    }
});

interface IOutOfServiceProps {
    params?:any;
    navigate?:any;    
    classes: any;
    refreshMyInfo: Function;
    myInfo?: IMe;
}

interface IOutOfServiceState {
}

let timer : any;

class OutOfService extends Component<IOutOfServiceProps, IOutOfServiceState> {
    
    componentDidMount() {
        //timer = setInterval(this.checkDisabled, 10000);
    }
    goBack = () => {
        this.props.refreshMyInfo();
        this.props.navigate('/');
    }
    checkDisabled=()=>{
        if(this.props.myInfo !== undefined && this.props.myInfo.activated == true){
            clearInterval(timer)
            this.props.navigate('/');
        }
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.containerStyle}>
                <img className="background-logo" alt='logo' />
                <div className={classes.titleStyle}>{'EN MANTENIMIENTO'}</div>
                <br />
                <button onClick={()=>window.location.replace(`${window.location.origin}${window.location.pathname}`)}>{'Volver a intentar.'}</button>
            </div>
        );
    }
}

export default withParamsAndNavigate(withStyles(styles)(OutOfService));