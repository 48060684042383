import React, { Component } from 'react';
import AppBar from './AppBar';
import { createStyles, withStyles, Theme } from '@material-ui/core/styles';
import VirtualizedList from './VirtualizedList';
import { IProduct, IFarm, INave, ISilo, IFestivo } from '../types';
import IconButton from '@material-ui/core/IconButton';
import { cloneDeep, findIndex, sortBy, find, filter } from 'lodash';
import OrderService from '../ApiClients/OrderService';
import ProductsIcon from '@material-ui/icons/PlaylistAddOutlined'
import ProductListItem from './ProductListItem';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';

import DateFnsUtils from '@date-io/date-fns';
import esLocale from "date-fns/locale/es";
import CommentIcon from '@material-ui/icons/Comment';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import DoIcon from '@material-ui/icons/OpenInNewOutlined';
import Summary from './Summary';
import orderIcon from '../Assets/cargas.png';
import {verifyDateTimeLimit, getNextOrden, GetFormattedPedidoDate, controlSiloProduct, allowAddQtySilo, controlProduct } from '../helpers'
import Loader from './Loader';
import CreateModal from './CreateDialog'
import ProductPicker from './ProductPicker';
import { withParamsAndNavigate } from './Material/ReactRouter';

const styles = (theme: Theme) => createStyles({
    itemDistribucion: {
        border: '2px solid #ccc',
        borderRadius: '15px',
        width: 'calc(100% - 20px)!important',
        boxShadow: '5px 5px 5px 0px rgba(0,0,0,0.50)',
        cursor: 'pointer',
        padding: '5px',
        position: 'relative',
    },
    tablecell: {
        fontSize: 12
    }
});

interface ICestaProps {
    params?:any;
    navigate?:any;    
    cart: Array<IProduct>;
    addOrRemoveFromCart: Function;
    updateCart: Function;
    farmRef?: string;
    dataareaid?:string;
    cartDate?: MaterialUiPickersDate;
    updateCartDate: (date: MaterialUiPickersDate, value?: string | null | undefined) => void;
    fullScreen?: boolean;
    cartComments?: string;
    updateCartComments?: any;
    idPedido?: number;
    resetCart: Function;
    classes:any;
    updateQuantityCart:Function;
}

interface ICestaState {
    farm?: IFarm;
    openCommentDialog: boolean;
    summaryMode: boolean;
    cartDate: MaterialUiPickersDate;
    inputText: any;
    creationModalOpen: boolean;
    modalType: number;
    typeAction: number;
    loadingModal: boolean;
    loading: boolean;
    titleModal:string;
    selectedProduct?:IProduct;
}

class Cesta extends Component<ICestaProps, ICestaState> {
    constructor(props: ICestaProps) {
        super(props);
        this.state = {
            openCommentDialog: false,
            summaryMode: false,
            cartDate: this.initialDatetime(),
            inputText:'',
            creationModalOpen: false,
            modalType: -1,
            typeAction: -1,
            loadingModal: false,
            loading: true,
            titleModal:'MENSAJE'
        };
    }

    initialDatetime = () => {
        if(this.props.cart.length > 0 && this.props.cartDate !== undefined){
            return this.props.cartDate;
        } else {
            let currentDatetime:Date = new Date();
            //let currentDay: number = currentDatetime.getDate();
            //currentDatetime.setDate(currentDay + 2);
            return  currentDatetime;
        }
    }

    calculateMinDate = () => {
        if(this.state.farm !== undefined){
            let currentDatetime:Date = new Date();
            let currentDay: number = currentDatetime.getDate();
            currentDatetime.setDate(currentDay + this.state.farm.beforeTimeLimitPedido);
            return currentDatetime;
        }
    }

    disableDays = (date:any, festivos:Array<IFestivo>) => {
        return date.getDay() === 0 || date.getDay() === 6 || findIndex(festivos, function(o){return new Date(o.fecha).toDateString() === date.toDateString()})>-1?true:false;
    }

    controlExistingDatetime = () => {
        const {farm} = this.state;
        if(farm){
            let dateTimePicker : Array<any> = verifyDateTimeLimit(this.state.cartDate, farm.timeLimitPedido, farm.beforeTimeLimitPedido, farm.festivos);
            this.setState({
                cartDate: dateTimePicker[0],
                modalType: 1,
                inputText: `¡ATENCIÓN! La fecha de entrega se ha modificado para el ${GetFormattedPedidoDate(dateTimePicker[0])}.`,
                creationModalOpen: dateTimePicker[1]
            })
            this.props.updateCartDate(dateTimePicker[0]);
        }
    }

    componentDidMount= async ()=> {
        await this.loadFarm();
        this.controlExistingDatetime();
    }

    componentDidUpdate(prevProps: ICestaProps) {
        if (this.props.farmRef !== prevProps.farmRef || this.props.dataareaid !== prevProps.dataareaid) {
            this.loadFarm();
        }
    }

    onCloseCreationModal = () => {
        this.setState({ creationModalOpen: false });
    }

    onToggleCreationModal = () => {
        this.setState({ creationModalOpen: !this.state.creationModalOpen });
    }

    handleDatePickerControl = (inputDate: any) => {
        const {farm} = this.state;

        if(farm){
            let dateTimePicker : Array<any> = verifyDateTimeLimit(inputDate, farm.timeLimitPedido, farm.beforeTimeLimitPedido, farm.festivos);
        
            this.setState({
                cartDate: dateTimePicker[0],
                modalType: 1,
                inputText: `¡ATENCIÓN! La fecha de entrega se ha modificado para el ${GetFormattedPedidoDate(dateTimePicker[0])}.`,
                creationModalOpen: dateTimePicker[1]
            })
            this.props.updateCartDate(dateTimePicker[0]);
        }
    }

    loadFarm = async () => {
        if (this.props.farmRef !== undefined && this.props.dataareaid) {
            let farm = await OrderService.getFarm(this.props.farmRef, this.props.dataareaid);
            this.setState({
                farm,
                loading: false
            });
        } else {
            this.setState({
                loading: false
            })
        }
    }

    goToProducts = () => {
        if (this.props.farmRef !== undefined || this.props.dataareaid !== undefined) {
            this.props.navigate(`/granja/${this.props.dataareaid}/${this.props.farmRef}/productos`)
        } else {
            this.props.navigate(`/granjas`)
        }
    }

    handleItemClick = (product: IProduct, index: number) => {
        this.props.navigate(`/granja/${this.props.dataareaid}/${this.props.farmRef}/producto/${product.ITEMID}`)
    }

    handleControlProduct = (product:IProduct, cart:Array<IProduct>, farm:IFarm, qty:number) =>{
        let result:Array<number|number> = controlProduct(product, cart, farm, qty);

        switch(result[0]){
            case 0: // Todo correcto
                break;
            case 1: 
                this.handleAlertQtyProduct(`¡ATENCIÓN! Se ha ajustado la cantidad al cajón del camión. ¿Quiere ir a la distribución de pienso?`, 2, 1, product);
                break;
            case 2: // Carga máxima del camión sobrepasada
                this.handleAlertQtyProduct(`¡ATENCIÓN! La cantidad excede el límite del camión (${farm.truckLoad} Kg), se ajustará la cantidad. ¿Quiere ir a la distribución de pienso?`, 2, 1, product);
                break;
            case 3: // Cantidad máxima de producto sobrepasada
                this.handleAlertQtyProduct(`¡ATENCIÓN! La cantidad excede el límite de la etapa, se ajustará la cantidad. ¿Quiere ir a la distribución de pienso?`, 2, 1, product);
                break;
            case 4: // No hay más cajones disponibles
                this.handleAlertQtyProduct(`¡ATENCIÓN! No hay más cajones disponibles.`, 1, -1);
                break;
            case -1:
                this.handleAlertQtyProduct(`¡ATENCIÓN! La cantidad permitida no completa 1 cajón.`, 1, -1);
                break;
        }
        return result[1];
    }

    handleAlertQtyProduct=(text:string, modalType:number, action:number, product?:IProduct)=>{
        this.setState({
            inputText: text,
            modalType: modalType,
            typeAction: action,
            creationModalOpen: true,
            selectedProduct:product,
            titleModal:'MENSAJE'
        })
    }

    handleAddUnitClick = (evt: any, product: IProduct, index: number) => {
        evt.stopPropagation();

        let newProducts: Array<IProduct> = cloneDeep(this.props.cart);
        let quantity = newProducts[index].quantity ? newProducts[index].quantity : 0;
        if (quantity !== undefined) {
            quantity += 1;
        }
        newProducts[index].quantity = quantity;

        this.props.updateCart(newProducts[index]);
    }

    handleRemoveUnitClick = (evt: any, product: IProduct, index: number) => {
        evt.stopPropagation();

        let newProducts: Array<IProduct> = cloneDeep(this.props.cart);
        let quantity = newProducts[index].quantity ? newProducts[index].quantity : 0;
        if (quantity !== undefined && quantity > 0) {
            quantity -= 1;
        }
        newProducts[index].quantity = quantity;

        this.props.updateCart(newProducts[index]);
    }
    
    handleClickManualUnit = (evt: any, product: IProduct, index: number) => {
        evt.stopPropagation();
    }

    handleChangeManualUnit = (evt: any, product: IProduct, index: number) => {
        evt.stopPropagation();
        let newProducts: Array<IProduct> = cloneDeep(this.props.cart);

        let quantity = Math.floor(Number(evt.target.value));

        newProducts[index].quantity = quantity;
        this.props.updateCart(newProducts[index]);
    }

    handleBlurManualUnit = (evt: any, index: number) => {
        let newProducts: Array<IProduct> = cloneDeep(this.props.cart);

        let qty = newProducts[index].quantity
        if(newProducts[index].TIPO == 1 && qty !== undefined && this.state.farm!== undefined){
            let result = this.handleControlProduct(newProducts[index], this.props.cart, this.state.farm, qty);
            if(qty == result)
                return;

            newProducts[index].quantity = result;
            
            this.props.updateCart(newProducts[index]);
        }
    }

    updateCart = (evt: any, product: IProduct, index: number) => {
        evt.stopPropagation();
        this.props.addOrRemoveFromCart(product, this.props.farmRef, this.props.dataareaid);
    }

    onDistributionClick = (evt: any, product: IProduct, index: number) => {
        evt.stopPropagation();
        this.handleRenderPicker(product);
    }

    handleControlSiloProduct = (silo:ISilo, product: IProduct, qty:number) =>{
        let result:Array<number|number> = controlSiloProduct(silo, product, qty);

        switch(result[0]){
            case 1: // Cantidad de silo excedida
                break;
            default:
                break;
        }
        return result[1];
    }

    handleAddSilo=(nave:INave, silo:ISilo, product:IProduct)=>{
        let newProduct = cloneDeep(product);
        let indexSilo = findIndex(product.silos, function(o){return o.NAVEID == nave.NAVEID && o.SILOID == silo.SILOID})

        if(indexSilo !== -1) {
            newProduct.silos.splice(indexSilo, 1);
        } else{
            silo.orden = getNextOrden(newProduct.silos);
            silo.quantity = 0 //getQuantitySilo(silo.capacidad, product.quantity!==undefined?product.quantity:0, getAcumuladoPienso(newProducts[indexProduct].silos));
            silo.NAVEID = nave.NAVEID;
            newProduct.silos.push(silo);
        }
        newProduct.silos = sortBy(newProduct.silos, [function(o) { return o.orden; }]);

        this.props.updateCart(newProduct);
        this.handleRenderPicker(newProduct)
    }

    handleDeleteSilo=(product:IProduct, indexSilo:number)=>{
        let newProduct = cloneDeep(product);

        newProduct.silos.splice(indexSilo,1);
        this.props.updateCart(newProduct);
        this.handleRenderPicker(newProduct)
    }
    
    handleConfChangeSilo=(product:IProduct, indexSilo:number, value:any, typeValue:any)=>{
        let newProduct = cloneDeep(product);

        newProduct.silos[indexSilo].lleno = false;
        newProduct.silos[indexSilo].resto = false;
        newProduct.silos[indexSilo].quantity = 0;
        switch(typeValue){
            case 1:
                newProduct.silos[indexSilo].quantity = this.handleControlSiloProduct(newProduct.silos[indexSilo], product, Number(value));;              
                break;
            case 2:
                newProduct.silos[indexSilo].lleno = value;
                break;
            case 3:
                newProduct.silos[indexSilo].resto = value;
                break;
        }
        this.props.updateCart(newProduct);
        this.handleRenderPicker(newProduct)
    }

    handleRenderPicker= async (product:IProduct)=>{
        if(this.state.farm !== undefined) {
            let newFarm = cloneDeep(this.state.farm);
            let newProduct = cloneDeep(product);

            let newSilos = newProduct.silos.map((silo:ISilo, indexSilo:number)=>{
                return({
                    ...silo,
                    handleDelete:()=>this.handleDeleteSilo(newProduct, indexSilo),
                    handleConfChange:this.handleConfChangeSilo,
                })
            })
            let newBuilding = newFarm.building.map((nave:INave)=>{
                let silos = nave.silo.map((silo:ISilo)=>{
                    return({
                        ...silo,
                        handleAddSilo:()=>this.handleAddSilo(nave, silo,newProduct)
                    })
                })
                return({
                    ...nave,
                    silo:silos
                })
            })
            newProduct.silos=newSilos;
            newFarm.building=newBuilding;

            this.setState({
                titleModal:'DISTRIBUCIÓN PIENSO',
                inputText: <ProductPicker style={{}} index={-1} data={[newFarm, newProduct]} />,
                modalType: 1,
                typeAction: 1,
                creationModalOpen: true
            })

        }

    }

    handleCommentClick = () => {
        this.setState({ openCommentDialog: !this.state.openCommentDialog });
    }

    handleCloseComment = () => {
        this.setState({ openCommentDialog: false });
    }

    toggleSummaryMode = () => {
        this.setState({ summaryMode: !this.state.summaryMode });
    }

    goBack=()=>{
        this.setState({ summaryMode:false });
    }

    handleDatePickerChange = (e: any) => {
        this.handleDatePickerControl(e);
    }

    handleCreateModal = (typeAction: number) => {
        switch(typeAction){
            case 1: // Abre la distribución del pienso
                if(this.state.selectedProduct == undefined)
                    return;
                this.handleRenderPicker(this.state.selectedProduct);
                break;
        }
    }

    // Resetea el carrito y envía al usuario en la pantalla de pedidos para ver los cambios
    afterSave = () => {
        this.props.resetCart();
        this.props.navigate(`/`);
    }

    render() {
        const {farm} = this.state;

        let items = this.props.cart.map((product, i) => {

            return {
                ...product,
                quantity: product.quantity,
                onItemClick: () => this.handleItemClick(product, i),
                onAddUnitClick: (evt: any) => this.handleAddUnitClick(evt, product, i),
                onRemoveUnitClick: (evt: any) => this.handleRemoveUnitClick(evt, product, i),
                onClickManualInput: (evt: any) => this.handleClickManualUnit(evt, product, i),
                onChangeManualInput: (evt: any) => this.handleChangeManualUnit(evt, product, i),
                onBlurManualInput: (evt: any) => this.handleBlurManualUnit(evt, i),
                updateCart: (evt: any) => this.updateCart(evt, product, i),
                onDistributionClick:(evt: any) => this.onDistributionClick(evt, product, i),
                isSameFarmRefCart:true
            }
        });
        if (this.state.summaryMode !== true) {
            return (
                <AppBar title={'Carga Pienso'} navigate={this.props.navigate}
                >
                    {this.props.cart.length !== 0 && farm !== undefined ?
                        <div>
                        <div style={{
                            padding: '15px',
                            borderRadius: '15px',
                            margin: '5px 10px',
                            width: 'calc(100% - 20px)!important',
                            boxShadow: '5px 5px 5px 0px rgba(0,0,0,0.31)',
                            cursor: 'pointer',
                            position: 'relative',
                        }}>
                            <p style={{ marginTop: '0px' }}>
                                <b style={{ color: '#284770' }}>{farm.name}</b>
                                <br />
                                <span style={{ fontSize: '12px', color: '#555' }}>{this.props.farmRef}</span>
                                <br />
                                <b style={{ color: '#284770' }}>{this.props.idPedido?this.props.idPedido:'Nuevo pedido'}</b>
                                <br />
                            </p>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                                <DatePicker
                                    label="Fecha de Entrega"
                                    value={this.state.cartDate}
                                    onChange={this.handleDatePickerChange}
                                    animateYearScrolling
                                    format="dd/MM/yyyy"
                                    minDate={this.calculateMinDate()}
                                    shouldDisableDate={(date:any)=>this.disableDays(date, farm.festivos)}
                                />
                            </MuiPickersUtilsProvider>

                            <IconButton onClick={this.handleCommentClick} style={{ float: 'right' }}>
                                <CommentIcon />
                            </IconButton>
                            <Dialog fullScreen={this.props.fullScreen} open={this.state.openCommentDialog} onClose={this.handleCloseComment} aria-labelledby="form-dialog-title">
                                <DialogTitle id="form-dialog-title">{'Comentarios'}</DialogTitle>
                                <DialogContent>
                                    <DialogContentText>
                                        {'Escriba aquí sus comentarios...'}
                                    </DialogContentText>
                                    <TextField
                                        autoFocus
                                        multiline
                                        margin="dense"
                                        id="name"
                                        label="Comentarios"
                                        type="text"
                                        fullWidth
                                        value={this.props.cartComments}
                                        onChange={this.props.updateCartComments}
                                    />
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={this.handleCloseComment} color="primary">
                                        {'Hecho'}
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </div>
                        <div style={{
                            position: 'absolute',
                            top: '180px',
                            left: 0,
                            right: 0,
                            bottom: '56px',
                        }}>
                            <VirtualizedList items={items} RowComponent={ProductListItem} itemSize={145} />
                            <CreateModal
                            onCloseCreationModal={this.onCloseCreationModal}
                            isOpen={this.state.creationModalOpen}
                            inputText={this.state.inputText}
                            typeModal={this.state.modalType}
                            action={this.handleCreateModal}
                            typeAction={this.state.typeAction}
                            loadingModal={this.state.loadingModal}
                            titleModal={this.state.titleModal}
                            />
                        </div>
                        </div>
                        :
                        this.state.loading?
                            <div style={{marginLeft:'50vh',marginTop:'150px'}}>
                            <Loader loading={this.state.loading}/>
                            </div>
                            :
                            <div style={{textAlign: 'center',marginTop:'150px'}}>
                                <h3>{'El camión está vacío'}</h3>
                                <img
                                alt="Cesta"
                                src={orderIcon}
                                style={{ height:'20%', width:'20%'}}
                                />
                            </div>
                    }
                    <div style={{ position: 'absolute', bottom: 0, left: 0, right: 0 }}>
                        <BottomNavigation
                            showLabels
                            style={{ backgroundColor: '#ddd' }}
                        >
                            <BottomNavigationAction label="Añadir Productos" icon={<ProductsIcon />} onClick={this.goToProducts} disabled={this.props.farmRef!==undefined?false:true}/>
                            {/*<BottomNavigationAction onClick={this.handleSaveDraft} label="Guardar Pedido" icon={<SaveIcon />} disabled={this.props.cart.length>0?false:true}/>*/}
                            <BottomNavigationAction label="Tramitar Pedido" icon={<DoIcon />} onClick={this.toggleSummaryMode} disabled={this.props.cart.length>0?false:true}/>
                        </BottomNavigation>
                    </div>
                </AppBar>
            );

        } else {
            return (
                <Summary
                    farm={this.state.farm}
                    cart={this.props.cart}
                    cartDate={this.state.cartDate}
                    cartComments={this.props.cartComments}
                    goBack={this.goBack}
                    status={-1}
                    pedidoId={this.props.idPedido}
                    pedido={undefined}
                    resetCart={this.props.resetCart}
                    updateCart={this.props.updateCart}
                    updateQuantityCart={this.props.updateQuantityCart}
                    type={1}
                />
            );
        }
    }
}
export default withParamsAndNavigate(withStyles(styles)(Cesta));