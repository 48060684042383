import * as React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Loader from './Loader';

interface ICreateModalProps {
    isOpen: boolean;
    onCloseCreationModal?: any;
    inputText: string;
    typeModal: number;
    action?: Function;
    typeAction?: number;
    loadingModal?: boolean;
    titleModal?:string;
    zIndex?:number;
};

interface ICreateModalState {
}

class CreateModal extends React.Component<ICreateModalProps, ICreateModalState> {

    constructor(props: ICreateModalProps) {
        super(props);
        this.state = {
        };
    }

    handleAction = () => {
        if(this.props.action !== undefined) {
            this.props.action(this.props.typeAction);
        }
    }

    renderButtons = () => {
        if(!this.props.loadingModal) {
            switch(this.props.typeModal) {
                case 1:
                    return(
                        <DialogActions>
                            <Button onClick={this.props.onCloseCreationModal} color="primary">
                            {`Ok`}
                            </Button>
                        </DialogActions>
                    );
                case 2:
                    return (
                        <DialogActions>
                            <Button onClick={this.props.onCloseCreationModal} color="primary">
                            {`No`}
                            </Button>
                            <Button onClick={this.handleAction} color="primary">
                            {`Sí`}
                            </Button>
                        </DialogActions>
                    );
                case 3:
                    return(
                        <DialogActions>
                            <Button onClick={this.handleAction} color="primary">
                            {`Ok`}
                            </Button>
                        </DialogActions>
                    );
                case 4:
                    return (
                        <DialogActions style={{display:'flex', justifyContent:'space-between', margin:5}}>
                            <Button onClick={this.props.onCloseCreationModal} color="primary">
                            {`Cancelar`}
                            </Button>
                            <Button onClick={this.handleAction} color="primary">
                            {`Tramitar`}
                            </Button>
                        </DialogActions>
                    );
                case 5:
                    return (
                        <DialogActions style={{display:'flex', justifyContent:'space-between', margin:5}}>
                            <Button onClick={this.props.onCloseCreationModal} color="primary">
                            {`Cancelar`}
                            </Button>
                            <Button onClick={this.handleAction} color="primary">
                            {`Guardar`}
                            </Button>
                        </DialogActions>
                    );
                default:
                    break;
            }
        } else {
            return(
                <div style={{marginLeft:'47%',marginTop:'5%'}}>
                    <Loader loading={true}/>
                </div>
            );
        }
    }

    render() {
        return (
            <Dialog
              open={this.props.isOpen}
              keepMounted
              aria-labelledby="alert-dialog-slide-title"
              aria-describedby="alert-dialog-slide-description"
              fullWidth
              style={{zIndex: this.props.zIndex}}
            >
              <DialogTitle id="alert-dialog-slide-title">{this.props.titleModal!==undefined?this.props.titleModal:"MENSAJE"}</DialogTitle>
              <DialogContent>
                  {this.props.inputText}
              </DialogContent>
              {this.renderButtons()}
            </Dialog>
        );
    }
}

export default CreateModal;