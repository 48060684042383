import React, { Component } from 'react';
import farmICon from '../Assets/farm.png';
import orderIcon from '../Assets/cargas.png';
import ExitApp from '@material-ui/icons/ExitToAppOutlined';
import AppsIcon from '@material-ui/icons/AppsOutlined';
import pedidosIcon from '../Assets/Add_Entrance.png';
import Badge from '@material-ui/core/Badge';
import { createStyles, withStyles, Theme } from '@material-ui/core/styles';
import { IMe, IProduct } from '../types';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import { authContext } from '../adalConfig';
import CreateModal from './CreateDialog'
import Loader from './Loader';
import controlIcon from '../Assets/Control.png';
import deliveryIcon from '../Assets/delivery-truck.svg';
import TutorialExplication from './Tutorial';
import { withParamsAndNavigate } from './Material/ReactRouter';

const styles = (theme: Theme) => createStyles({
    badge: {
        top: '10px',
        right: '5px',
    }
});

interface IHomeButtonProps {
    id: string;
    name: string;
    icon: any;
    goToFeature: Function;
    classes: any;
    cartProductCount?: number;
}

interface IHomeButtonState {
}

class HomeButtonInternal extends Component<IHomeButtonProps, IHomeButtonState> {
    render() {
        let imgComponent = <img src={this.props.icon} alt='icon' className='feature-icon' />;
        return (
            <div className='home-button' onClick={() => this.props.goToFeature(this.props.id)}>
                {this.props.id === 'cesta' ?
                    <Badge badgeContent={this.props.cartProductCount} color="secondary" classes={{ badge: this.props.classes.badge }}>
                    {imgComponent}
                    </Badge>
                    :
                    imgComponent
                }

                <div className='button-text'>
                    {this.props.name}
                </div>
            </div>
        );
    }
}
const HomeButton = withStyles(styles)(HomeButtonInternal);

interface IHomeProps {
    myInfo?: IMe;
    params?:any;
    navigate?:any;    
    cart: Array<IProduct>;
    version:string;
}

interface IHomeState {
    modalType: number;
    typeAction: number;
    inputText: string;
    creationModalOpen: boolean;
}

class Home extends Component<IHomeProps, IHomeState> {
    constructor(props: IHomeProps) {
        super(props);
        this.state = {
            modalType: -1,
            typeAction: -1,
            inputText:'',
            creationModalOpen: false
        };
    }

    goToFeature = (id: string) => {
        this.props.navigate(`/${id}`);
    }

    renderHomeMenu=()=>{
        const {myInfo} = this.props;

        if(myInfo) {
            return(
                <div className='buttons-container'>
                    {myInfo.roles.some( ai => [10, 50, 100].includes(ai.role))?
                        <React.Fragment>
                            <HomeButton
                                goToFeature={this.goToFeature}
                                id={'granjas'}
                                name={'Realizar Pedido'}
                                icon={farmICon}
                            />
                            <HomeButton
                                    goToFeature={this.goToFeature}
                                    id={'cesta'}
                                    name={'Carga pienso'}
                                    icon={orderIcon}
                                    cartProductCount={this.props.cart.length}
                            />
                            <HomeButton
                                goToFeature={this.goToFeature}
                                id={'pedidos'}
                                name={'Pedidos'}
                                icon={pedidosIcon}
                            />
                            {myInfo.supervisor?
                                <HomeButton
                                goToFeature={this.goToFeature}
                                id={'reviewFarms'}
                                name={'Supervisión'}
                                icon={controlIcon}
                                />
                            :
                                null
                            }
                        </React.Fragment>
                    :
                        null
                    }

                    {myInfo.roles.some( ai => [30, 50, 100].includes(ai.role))?
                        <HomeButton
                        goToFeature={this.goToFeature}
                        id={'delivery'}
                        name={'Entrega pienso'}
                        icon={deliveryIcon}
                        />
                    :
                        null
                    }
                </div>
            );
        }
        else {
            return(
                <div style={{marginLeft:'50%',marginTop:200}}>
                    <Loader loading={true}/>
                </div>
                );
        }
    }

    onCloseCreationModal = () => {
        this.setState({ creationModalOpen: false });
    }

    onToggleCreationModal = () => {
        this.setState({ creationModalOpen: !this.state.creationModalOpen });
    }

    handleExitApp = () =>{
        this.setState({
            inputText:'¿Quiere cerrar la sesión?',
            modalType: 2,
            typeAction: 2,
            creationModalOpen: true
        })
    }

    handleNealiaHub = () =>{
        this.setState({
            inputText:'¿Quiere volver a las aplicaciones de Nealia?',
            modalType: 2,
            typeAction: 1,
            creationModalOpen: true
        })
    }

    handleCreateModal = (typeAction: number) => {
        switch(typeAction) {
            case 1:
                window.location.href = "https://hub.nealia.tech/private_aplicaciones";
                break;
            case 2:
                this.appLogOut();
                break;
            default:
                break;
        }
    }

    appLogOut = () => {
        authContext.logOut();
    }

    render() {
        return (
            <div>
                {/*TutorialExplication()*/}
                <div className='home-container'>
                    <div className='background-container'>
                        <div className='background-gradient' />
                        <div className='image-background' />
                    </div>
                    <div className='home-content'>
                        <div style={{position:'absolute', left:10, top:10}}>{`${this.props.version}`}</div>
                            <div style={{ textAlign: 'center' }}>
                                <img alt='logo' className='background-logo' />
                            </div>
                        <div className='home-title-user'>
                            {'Bienvenido'}
                            <br />
                            {this.props.myInfo !== undefined && this.props.myInfo !== null?this.props.myInfo.userIdName:''}
                        </div>
                        {this.renderHomeMenu()}
                        <CreateModal
                                onCloseCreationModal={this.onCloseCreationModal}
                                isOpen={this.state.creationModalOpen}
                                inputText={this.state.inputText}
                                typeModal={this.state.modalType}
                                action={this.handleCreateModal}
                                typeAction={this.state.typeAction}
                                loadingModal={false}
                            />
                    </div>
                </div>
                <div style={{ position: 'absolute', bottom: 0, left: 0, right: 0 }}>
                        
                        {this.props.myInfo!==undefined?
                        <BottomNavigation
                            showLabels
                            style={{ backgroundColor: '#ddd' }}
                        >
                            <BottomNavigationAction label={"Mis aplicaciones"} icon={<AppsIcon/>} onClick={this.handleNealiaHub} disabled={this.props.myInfo===undefined?true:false}/>
                            <BottomNavigationAction label={"Cerrar sesión"} icon={<ExitApp/>} onClick={this.handleExitApp} disabled={this.props.myInfo===undefined?true:false}/>
                        </BottomNavigation>
                        :
                        <BottomNavigation
                        showLabels
                        style={{ backgroundColor: '#ddd' }}
                        >
                            {''}
                        </BottomNavigation>
                        }
                </div>
            </div>
        );
    }
}

export default withParamsAndNavigate(Home);