import React from 'react';
import { IProduct } from '../types';
import PropTypes from 'prop-types';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { ListChildComponentProps } from 'react-window';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/ArrowForwardIos';
import MinusIcon from '@material-ui/icons/RemoveCircleOutlineOutlined';
import TruckIcon from '@material-ui/icons/LocalShippingOutlined';
import PriorityIcon from '@material-ui/icons/LowPriorityOutlined';
import RemoveCartIcon from '@material-ui/icons/RemoveShoppingCartOutlined'
import AddIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles(createStyles({
    img: {
        objectFit: 'contain',
    },
    root: {
        borderRadius: 0,
    },
    item: {
        // border: '1px solid #ccc',
        borderRadius: '15px',
        margin: '5px 10px',
        width: 'calc(100% - 20px)!important',
        boxShadow: '5px 5px 5px 0px rgba(0,0,0,0.31)',
        border: '1px solid #ccc',
        cursor: 'pointer',
        paddingBottom: '50px',
        position: 'relative',
    },
    bottomActionsContainer: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        height: '30px',
        borderTop: '1px solid rgba(0,0,0,0.31)',
        borderBottomLeftRadius: '15px',
        borderBottomRightRadius: '15px',
        padding: '5px 0px',
        color: '#284770',
    },
    quantIcon: {
        paddingTop: '3px',
        paddingBottom: '3px',
        color: '#284770',
        verticalAlign: 'middle',
    },
    bottomRightActionsContainer: {
        position: 'absolute',
        right: '0px',
        top: '0px',
        bottom: '0px',
        zIndex: 1,
        fontSize:'12px'
    },
    promotion: {
        //backgroundColor: '#f00',
        display: 'inline-block',
        padding: '3px 15px',
        //color: '#fff',
        verticalAlign: 'middle',
    },
    separator: {
        display: 'inline-block',
        borderRight: '1px solid rgba(0,0,0,0.31)',
        verticalAlign: 'middle',
        height: '100%',
        marginLeft: '15px'
    },
    textField: {
        marginLeft: '10px',
        marginRight: '10px',
    }
}));

const ProductListItem = (props: any) => {
    const { index, style, data } = props;
    const classes = useStyles();
    let item: IProduct = data[index];

    return (
        <div style={style} key={index}>
            <ListItem button classes={{ root: classes.item }} onClick={item.onItemClick}>
                {item.TIPO == 1 && item.isInCart?
                    <ListItemIcon>
                    <IconButton disabled={!item.isSameFarmRefCart} onClick={item.onDistributionClick} className={classes.quantIcon}>
                        <PriorityIcon />
                    </IconButton>
                    </ListItemIcon>
                :
                    ''
                }
                <ListItemText style={{whiteSpace:'nowrap', overflowX:'auto'}} primary={item.ITEMNAME} secondary={`Etapa: ${item.numEtapa} / Lote: ${item.batchId}`} />
                <div className={classes.bottomActionsContainer}>
                    {item.allowItem == undefined?
                        <div>
                            {item.TIPO == 2?
                                <IconButton disabled={!item.isSameFarmRefCart} onClick={item.onRemoveUnitClick} className={classes.quantIcon}>
                                    <MinusIcon />
                                </IconButton>
                            :
                                <a style={{marginLeft:20}}/>
                            }
                            <TextField
                            id="standard-number"
                            value={item.quantity ? item.quantity : ''}
                            disabled={!item.isSameFarmRefCart}
                            //defaultValue={item.quantity ? item.quantity : 0}
                            onChange={item.onChangeManualInput}
                            onClick={item.onClickManualInput}
                            onBlur={item.onBlurManualInput}
                            type="number"
                            className={classes.textField}
                            style={{width:item.TIPO==2?60:80}}
                            InputLabelProps={{
                            shrink: true,
                            }}
                            />
                            {item.TIPO == 2?
                                <IconButton disabled={!item.isSameFarmRefCart} onClick={item.onAddUnitClick} className={classes.quantIcon}>
                                    <AddIcon />
                                </IconButton>
                            :
                                <a style={{marginLeft:20}}/>
                            }
                            {` ${item.unitId} `}
                            <div  className={classes.bottomRightActionsContainer}>
                                <div className={classes.promotion} style={{backgroundColor:item.typeColor, color:'#ffffff'}}>
                                    {item.typeDsc}
                                </div>
                                <div className={classes.separator} />
                                <IconButton onClick={item.updateCart} className={classes.quantIcon}>
                                    {item.isInCart === true ? <RemoveCartIcon /> : <TruckIcon />}
                                </IconButton>
                            </div>
                        </div>
                    :
                        <label style={{marginLeft:`15px`}}>{`${item.allowItem}`}</label>
                    }
                </div>
                <ListItemSecondaryAction style={{ paddingBottom: '40px' }}>
                    <IconButton edge="end" aria-label="Comments" onClick={item.onItemClick}>
                        <DeleteIcon />
                    </IconButton>
                </ListItemSecondaryAction>
            </ListItem>
        </div>
    );
}

ProductListItem.propTypes = {
    index: PropTypes.number,
    style: PropTypes.object,
} as any;

export default ProductListItem;