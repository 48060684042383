import { AuthenticationContext, adalFetch, withAdalLogin } from 'react-adal';

export const adalConfig = {
    clientId: '6980257a-e8e9-46e0-9952-86a5a10a0511',
    tenant: '779fefa3-287c-452b-9106-b9cbb436236e',
    endpoints: {
        api: '6980257a-e8e9-46e0-9952-86a5a10a0511',
    },
    cacheLocation: 'localStorage',
};

export const authContext = new AuthenticationContext(adalConfig);

export const adalApiFetch = (fetch, url, options) =>
    adalFetch(authContext, adalConfig.endpoints.api, fetch, url, options);

export const withAdalLoginApi = withAdalLogin(authContext, adalConfig.endpoints.api);

export const getToken = () => authContext.getCachedToken(adalConfig.clientId);