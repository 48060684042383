import React, { Component } from 'react';
import { IProduct, ISilo, INave, IFarm, IIPedido } from '../types';
import PropTypes from 'prop-types';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { ListChildComponentProps } from 'react-window';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import {sortBy, findIndex, filter, groupBy} from 'lodash';
import { TextFieldInput } from './FormsWidgets';
import { CheckNumberPlate } from '../helpers';

const useStyles = makeStyles(createStyles({
    itemDistribucion: {
        border: '2px solid #ccc',
        borderRadius: '15px',
        // width: 'calc(100% - 20px)!important',
        // boxShadow: '5px 5px 5px 0px rgba(0,0,0,0.50)',
        // cursor: 'pointer',
        // padding: '5px',
        // marginRight:'20px',
        position: 'relative',
        textAlign:'center',
        fontWeight: 'bold'
    },
    tablecell: {
        fontSize: 12
    }
}));

const ProductPicker = (props: ListChildComponentProps) => {
    const { index, style, data } = props;
    const classes = useStyles();
    let type: number = data[0];
    let pedido: IIPedido = data[1];

    if(type == 1) { // Entrega de pienso
        const group_product = groupBy(pedido.products, 'typeDsc');

        return (
            <div style={{overflowY:'auto', height:'60vh', overflowX:'hidden'}}>
                <div>
                    <TextFieldInput
                        key={0}
                        name={`Matrícula camión`}
                        id={`deliveryTruckNumberplate`}
                        fieldsData={pedido}
                        handleFieldChange={(id:string, evt:any)=>pedido.handleChange(id, evt)}
                        size="medium"
                        shrink={true}
                        type={"text"}
                        required={true}
                        style={3}
                        helper={`Ejemplo: 0000AAA`}
                        maxText={7}
                        noError={pedido.deliveryTruckNumberplate?CheckNumberPlate(1, pedido.deliveryTruckNumberplate):false}
                        disabled={false}
                    />
                </div>

                <div>
                    <TextFieldInput
                        key={0}
                        name={`Matrícula remolque`}
                        id={`deliveryTrailerNumberplate`}
                        fieldsData={pedido}
                        handleFieldChange={(id:string, evt:any)=>pedido.handleChange(id, evt)}
                        size="medium"
                        shrink={true}
                        type={"text"}
                        required={true}
                        style={3}
                        helper={`Ejemplo: A0123BCD`}
                        maxText={8}
                        noError={pedido.deliveryTrailerNumberplate?CheckNumberPlate(2, pedido.deliveryTrailerNumberplate):false}
                        disabled={false}
                    />
                </div>

                <div className={classes.itemDistribucion}>
                    <p style={{fontSize:15}}>{`Total productos: ${pedido.products.length}`}</p>
                </div>

                <br/>
                <div style={{display:'flex', width:'100%'}}>
                <FormControl component="fieldset" style={{padding:'3', width:'inherit'}}>
                    <FormLabel style={{marginBottom:'5px'}} component="legend">
                        {`Artículos a entregar:`}
                    </FormLabel>

                    {Object.keys(group_product).map((title:string, idx:number)=>{
                        return (
                            <div style={{width:'100%'}}>
                                <p style={{paddingBottom:'4px', width:'100%', backgroundColor:group_product[title][0].typeColor, color:'#ffffff', paddingLeft: 10}}>
                                    {`${title}`}
                                </p>
                                {group_product[title].map((row:IProduct, index:number) => (
                                    <div>
                                        <p><strong>{`${row.ITEMID} - ${row.ITEMNAME}`}</strong></p>

                                        <Paper style={{border:'1px solid #ddd', marginTop: 10/*, marginRight:'20px'*/}}>

                                        <div style={{overflowX:'auto'}}>
                                            <Table>
                                                <TableHead> 
                                                <TableRow>
                                                    <TableCell padding="checkbox" size="small" className={classes.tablecell} align="center">{`Cantidad prevista`}</TableCell>
                                                    <TableCell padding="checkbox" size="small" className={classes.tablecell} align="center">{`Cantidad entregada`}</TableCell>
                                                </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                <TableRow key={index}>
                                                    <TableCell style={{paddingBottom:'4px'}} padding="checkbox" size="small" align="center"><strong>{`${row.quantity} ${row.unitId}`}</strong></TableCell>
                                                    <TableCell padding="checkbox" size="small" align="center"><input id='siloQty' type='number' min={1} step={1} style={{paddingLeft:'5px', paddingRight:'5px', width:'70px'}} value={String(row.quantityDelivered)} onChange={(evt:any)=>row.handleChange(row, evt)}></input></TableCell>
                                                </TableRow>
                                                </TableBody>
                                            </Table>
                                        </div>
                                        </Paper>
                                    </div>
                                ))}
                            </div>
                        )
                    })}
                </FormControl>
                </div>

                <br/>
                <div>
                    <TextFieldInput
                        key={0}
                        name={`Observaciones`}
                        id={`deliveryComments`}
                        fieldsData={pedido}
                        handleFieldChange={(id:string, evt:any)=>pedido.handleChange(id, evt)}
                        size="medium"
                        shrink={true}
                        type={"text"}
                        required={false}
                        style={3}
                        multiline
                        minRows={3}
                        helper={``}
                        maxText={1000}
                        noError={true}
                        disabled={false}
                    />
                </div>
            </div>
        );
    }
    else if(type == 2) // Edición datos pedido
        return (
            <div style={{overflowY:'auto', height:'60vh', overflowX:'hidden'}}>
                <div>
                    <TextFieldInput
                        key={0}
                        name={`Matrícula camión`}
                        id={`deliveryTruckNumberplate`}
                        fieldsData={pedido}
                        handleFieldChange={(id:string, evt:any)=>pedido.handleChange(id, evt)}
                        size="medium"
                        shrink={true}
                        type={"text"}
                        required={true}
                        style={3}
                        helper={`Ejemplo: 0000AAA`}
                        maxText={7}
                        noError={pedido.deliveryTruckNumberplate?CheckNumberPlate(1, pedido.deliveryTruckNumberplate):false}
                        disabled={![30, 40].includes(pedido.status)}
                    />
                </div>

                <div>
                    <TextFieldInput
                        key={0}
                        name={`Matrícula remolque`}
                        id={`deliveryTrailerNumberplate`}
                        fieldsData={pedido}
                        handleFieldChange={(id:string, evt:any)=>pedido.handleChange(id, evt)}
                        size="medium"
                        shrink={true}
                        type={"text"}
                        required={true}
                        style={3}
                        helper={`Ejemplo: A0123BCD`}
                        maxText={8}
                        noError={pedido.deliveryTrailerNumberplate?CheckNumberPlate(2, pedido.deliveryTrailerNumberplate):false}
                        disabled={![30, 40].includes(pedido.status)}
                    />
                </div>
            </div>
        );
    else
        return null;
}
ProductPicker.propTypes = {
    index: PropTypes.number,
    style: PropTypes.object,
} as any;

export default ProductPicker;