import React, { Component } from 'react';
import './App.css';
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { IMe, IProduct } from './types';
import Home from './Components/Home';
import Farms from './Components/Farms';
import ReviewFarms from './Components/ReviewFarms';
import ReviewPedidos from './Components/ReviewPedidos';
import ReviewPedidoDetail from './Components/ReviewPedidoDetail';
import Products from './Components/Products';
import FarmDetail from './Components/FarmDetail';
import OutOfService from './Components/OutOfService';
import Unauthorized from './Components/Unauthorized';
import OrderService from './ApiClients/OrderService';
import Pedidos from './Components/Pedidos';
import PedidoDetail from './Components/PedidoDetail';
import { cloneDeep } from 'lodash';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import Cesta from './Components/Cesta';
import ProductDetail from './Components/ProductDetail';
import appData from './ApiClients/appData';
import { withParamsAndNavigate } from './Components/Material/ReactRouter';

let timerGetData:any = undefined;
let version:string = 'v2.0.0'; // 06-09-2024

interface IAppProps {
  params?:any;
  navigate?:any; 
}

interface IAppState {
  myInfo?: IMe;
  cart: Array<IProduct>;
  otherProductFarm: boolean;
  cartFarmRef?: string;
  cartDataareaid?:string;
  idPedido?:number;
  cartDate?: MaterialUiPickersDate;
  cartComments: string;
}

class App extends Component<IAppProps, IAppState> {
  constructor(props: IAppProps) {
    super(props);
    this.state = {
      myInfo: undefined,
      cart:[],
      otherProductFarm: false,
      cartFarmRef: undefined,
      cartDataareaid: undefined,
      cartDate: undefined, 
      idPedido: undefined,
      cartComments: '',   
    };
  }

  componentWillMount(){


    this.getMyInfo();
    OrderService.metricService(1); // Guarda el registro del primer Login
  }

  componentDidMount(){
    timerGetData = setInterval(this.refreshData, 300000);
  }

  refreshData = async () => {
    clearInterval(timerGetData);

    OrderService.getFarms(true);
    OrderService.getProducts(true);

    timerGetData = setInterval(this.refreshData, 300000);
  }

  metrics = async (metricType:number) => { // Registra el acceso del usuario, el fallo no autorizado y el fallo por mantenimiento
    await OrderService.metricService(metricType);
  }

  getMyInfo = async () => {
    let response = await OrderService.me();
    //this.setState({ myInfo:response });
    this.checkUserPermission(response);
  }
    
  checkUserPermission=(inputInfo:IMe)=>{ // Verifica los permisos del usuario //
    if(inputInfo !== null) {
      if(!inputInfo.authorized) { // Actualiza el estado MyInfo en caso de haber obtenido correctamente la información del usuario
        this.setState({ myInfo: undefined });
        this.props.navigate('/noAutorizado');
        this.metrics(2);
      } else if(!inputInfo.activated) {
          this.props.navigate('/fueraServicio');
          this.metrics(3);
      } else {
        this.setState({ myInfo: inputInfo });
      }
    } else if(inputInfo == undefined){
      this.props.navigate('/noAutorizado');
      this.metrics(2);
    } else {
      this.props.navigate('/noAutorizado');
    }
  }

  addOrRemoveFromCart = (product: IProduct, farmRef: string, dataareaid:string) => {
    if ((this.state.cart.length !== 0 && farmRef !== this.state.cartFarmRef) || (this.state.cart.length !== 0 && dataareaid !== this.state.cartDataareaid)) {
      this.setState({
        otherProductFarm: true
      })
      return;
    }

    let newCart: Array<IProduct> = cloneDeep(this.state.cart);

    let isInCart = newCart.find((item) => {
      return (item.ITEMID + item.dataareaid + item.feedStage + item.batchId) === (product.ITEMID + product.dataareaid + product.feedStage + product.batchId);
    }) !== undefined ? true : false;

    if (isInCart) {
      product.isInCart = false;
      product.silos = [];
      product.quantity = undefined;
      newCart = newCart.filter((item) => {
        return (item.ITEMID + item.dataareaid + item.feedStage + item.batchId) !== (product.ITEMID + product.dataareaid + product.feedStage + product.batchId);
      })
    } else {
      product.isInCart = true;
      newCart.push(product);
    }

    this.setState((prevState, props) =>({
      cart: newCart,
      cartDataareaid: dataareaid,
      cartFarmRef: farmRef,
      idPedido: farmRef !== prevState.cartFarmRef?undefined:prevState.idPedido,
      cartDate: farmRef !== prevState.cartFarmRef?undefined:prevState.cartDate
    }))
  }

  recreateCart = (products: Array<IProduct>, farmRef: string, id: number, date:string) => {
    this.setState({ 
      cart: products,
      cartFarmRef: farmRef,
      idPedido: id,
      cartDate: new Date(date)
    })
  }

  updateCart = (product: IProduct, changeQty?:boolean) => {
    console.log(product)
    let isInCart = this.state.cart.find((item) => {
      return (item.ITEMID + item.dataareaid + item.feedStage + item.batchId) === (product.ITEMID + product.dataareaid + product.feedStage + product.batchId);
    }) !== undefined ? true : false;
    
    if (isInCart === true) {

      let newCart: Array<IProduct> = cloneDeep(this.state.cart);
      newCart = newCart.map((item) => {
        console.log(item)
        if ((item.ITEMID + item.dataareaid + item.feedStage + item.batchId) === (product.ITEMID + product.dataareaid + product.feedStage + product.batchId)) {
          if(changeQty){
            item.quantity = product.quantity;
            return item;
          } else {
            return product;
          }
        }
        return item;

      });
      //console.log('newCart', newCart)
      this.setState({ cart: newCart })
    }
  }

  updateQuantityCart = (newCart: Array<IProduct>) => {
      this.setState({ cart: newCart })
  }

  updateCartDate = (date: MaterialUiPickersDate, value?: string | null | undefined) => {
    this.setState({ cartDate: date })
  }

  updateCartComments = (evt: any) => {
    this.setState({ cartComments: evt.target.value });
  }

  resetCart = () => {
    // Reset Silos del producto, pero mejor cambiar al producto del carrito y dejar la lista de productes "virgen"
    let newProducts:Array<IProduct> = appData.getListProducts();
    newProducts.map(item=>{
      return({
        ...item,
        silos:[]
      })
    })
    appData.setListProducts(newProducts);

    this.setState({
      cart:[],
      cartDate:undefined,
      cartFarmRef: undefined,
      cartDataareaid: undefined,
      otherProductFarm: false,
      cartComments:''
    })
  }

  resetOtherFarmParameter = () => {
    this.setState({
      otherProductFarm: false
    })
  }

  render() {
    return (
      <Routes>
        <Route
          path="/"
          element={
            <Home
              version={version}
              cart={this.state.cart}
              myInfo={this.state.myInfo}
            />
          }
        />
        <Route
        path="/granjas"
        element={
          <Farms
            myInfo={this.state.myInfo}
          />
          }
        />
        <Route
          path="/granja/:dataareaid/:farmRef"
          element={
            <FarmDetail
              myInfo={this.state.myInfo}
            />
          }
        />
        <Route
          path="/granja/:dataareaid/:farmRef/productos"
          element={
            <Products
              cart={this.state.cart}
              cartFarmRef={this.state.cartFarmRef}
              cartDataareaidRef={this.state.cartDataareaid}
              updateCart={this.updateCart}
              addOrRemoveFromCart={this.addOrRemoveFromCart}
              otherProductFarm={this.state.otherProductFarm}
              resetOtherFarmParameter={this.resetOtherFarmParameter}
            />
          }
        />
        <Route
          path="/cesta"
          element={
            <Cesta
              cart={this.state.cart}
              farmRef={this.state.cartFarmRef}
              dataareaid={this.state.cartDataareaid}
              addOrRemoveFromCart={this.addOrRemoveFromCart}
              updateCart={this.updateCart}
              cartDate={this.state.cartDate}
              idPedido={this.state.idPedido}
              updateCartDate={this.updateCartDate}
              cartComments={this.state.cartComments}
              updateCartComments={this.updateCartComments}
              resetCart={this.resetCart}
              updateQuantityCart={this.updateQuantityCart}
            />
          }
        />
        <Route
          path="/granja/:dataareaid/:farmRef/producto/:productRef"
          element={
            <ProductDetail
            />
          }
        />
        <Route
          path="/noAutorizado"
          element={
            <Unauthorized
              refreshMyInfo={this.getMyInfo}
              myInfo={this.state.myInfo}
            />
          }
        />
        <Route
          path="/fueraServicio"
          element={
            <OutOfService
              refreshMyInfo={this.getMyInfo}
              myInfo={this.state.myInfo}
            />
          }
        />
        <Route
          path="/pedidos"
          element={
            <Pedidos
              type={1}
              title='Pedidos'
            />
          }
        />
        <Route
          path="/delivery"
          element={
            <Pedidos
              type={2}
              title='Ruta pedidos'
            />
          }
        />
        <Route
          path="/pedidos/:dataareaid/:farmRef"
          element={
            <ReviewPedidos
              myInfo={this.state.myInfo}
            />
          }
        />
        <Route
          path="/pedido/:dataareaid/:pedidoRef"
          element={
            <PedidoDetail
              updateCart={this.recreateCart}
              resetCart={this.resetCart}
              myInfo={this.state.myInfo}
            />
          }
        />
        <Route
          path="/delivery/:dataareaid/:pedidoRef"
          element={
            <PedidoDetail
              updateCart={this.recreateCart}
              resetCart={this.resetCart}
              myInfo={this.state.myInfo}
            />
          }
        />
        <Route
          path="/pedidos/:dataareaid/:farmRef/:pedidoRef"
          element={
            <ReviewPedidoDetail
              updateCart={this.recreateCart}
              resetCart={this.resetCart}
              myInfo={this.state.myInfo}
            />
          }
        />
        <Route
          path="/reviewFarms"
          element={
            <ReviewFarms
              myInfo={this.state.myInfo}
            />
          }
        />
        <Route
          path="*"
          element={<Navigate to="/" replace />}
        />      
      </Routes>
    );
  }
}

export default withParamsAndNavigate(App);
