import React, { Component } from 'react';
import AppBar from './AppBar';
import VirtualizedList from './VirtualizedList';
import { IProduct, IFarm, INave, ISilo } from '../types';
import SearchField from './SearchField';
import { cloneDeep, findIndex, sortBy } from 'lodash';
import OrderService from '../ApiClients/OrderService';
import Badge from '@material-ui/core/Badge';
import ProductListItem from './ProductListItem';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import Loader from './Loader';
import {searchProducts, getNextOrden, controlProduct, controlSiloProduct, getProductsCurrentFeedPlanStage, allowAddQtySilo} from '../helpers'
import CreateModal from './CreateDialog';
import { createStyles, withStyles, Theme } from '@material-ui/core/styles';
import TruckIcon from '@material-ui/icons/LocalShippingRounded';
import ProductPicker from './ProductPicker';
import { withParamsAndNavigate } from './Material/ReactRouter';

const styles = (theme: Theme) => createStyles({
    itemDistribucion: {
        border: '1px solid #ccc',
        borderRadius: '15px',
        width: 'calc(100% - 20px)!important',
        boxShadow: '5px 5px 5px 0px rgba(0,0,0,0.50)',
        cursor: 'pointer',
        padding: '5px',
        position: 'relative',
    },
    tablecell: {
        fontSize: 12
    }
});

interface IProductsProps {
    params?:any;
    navigate?:any;    
    cart: Array<IProduct>;
    addOrRemoveFromCart: Function;
    updateCart: Function;
    otherProductFarm: boolean;
    resetOtherFarmParameter: Function;
    classes:any;
    cartFarmRef?:string;
    cartDataareaidRef?:string;
}

interface IProductsState {
    products: Array<IProduct>;
    searchValue: string|null;
    loading: boolean;
    filteredProducts: Array<IProduct>;
    inputText: any;
    titleModal:string;
    creationModalOpen: boolean;
    modalType: number;
    typeAction: number;
    loadingModal: boolean;
    selectedProduct?:IProduct;
    farm?:IFarm
}

class Products extends Component<IProductsProps, IProductsState> {
    constructor(props: IProductsProps) {
        super(props);
        this.state = {
            products: [],
            searchValue: localStorage.getItem('searchValueProduct'),
            loading: true,
            filteredProducts:[],
            inputText:'',
            creationModalOpen: false,
            modalType: -1,
            typeAction: -1,
            loadingModal: false,
            titleModal:'MENSAJE'
        };
    }

    componentDidMount = async () => {
        await this.loadProducts(this.props.params.farmRef, this.props.params.dataareaid);
    }

    componentWillReceiveProps(prevProps: IProductsProps) {
        if (this.props.params.farmRef !== prevProps.params.farmRef || this.props.params.dataareaid !== prevProps.params.dataareaid) {
            this.loadProducts(prevProps.params.farmRef, prevProps.params.dataareaid);
        }
        if(this.props.otherProductFarm !== prevProps.otherProductFarm) {
            if(prevProps.otherProductFarm) {
                this.setState({
                    inputText: `¡ATENCIÓN! La cesta contiene productos de otra tienda, no se puede incluir este artículo.`,
                    modalType: 1,
                    creationModalOpen: true,
                })
                this.props.resetOtherFarmParameter();
            }
        }
    }

    onCloseCreationModal = () => {
        this.setState({ creationModalOpen: false });
    }

    onToggleCreationModal = () => {
        this.setState({ creationModalOpen: !this.state.creationModalOpen });
    }

    loadProducts = async (farmId:string, dataareaid:string) => {
        let farmData:IFarm|undefined = await OrderService.getFarm(farmId, dataareaid);

        let products:Array<IProduct> = await OrderService.getProducts(false);

        if(products !== undefined && farmData !== undefined) {

            products = getProductsCurrentFeedPlanStage(products, farmData); // Carga los productos y filtra los piensos (TIPO 1) vigentes en el plan de alimentación

            this.setState({
            products,
            filteredProducts: searchProducts(products, this.state.searchValue?this.state.searchValue:''),
            loading: false,
            farm: farmData
            })
        } else {
            this.setState({
                loading: false
            }) 
        }


    }

    handleItemClick = (product: IProduct, index: number) => {
        if(this.state.farm !== undefined) {
            this.props.navigate(`/granja/${this.state.farm.dataareaid}/${this.state.farm.id}/producto/${product.ITEMID}`)
        }
    }

    goToCart = () => {
        this.props.navigate('/cesta');
    }

    handleValueChange = (evt: any) => {
        localStorage.setItem('searchValueProduct', evt.target.value) // Guardar en el localstorage la variable de búsqueda
        this.searchProducts(evt.target.value)
        this.setState({ 
            searchValue: evt.target.value,
        })
    }

    searchProducts = (inputSearch: string) => {
    /*let items = this.state.products.map((product, i) => {
        let Product = this.state.filteredProducts.find((item) => {
            return item.ITEMID === product.ITEMID;
        });

        if(Product !== undefined) {
            product.quantity = Product.quantity
        }
        return {
            ...product,
            quantity: Product !== undefined ? Product.quantity : product.quantity,
        }
    })*/

    let newFilteredProducts : Array<IProduct> = searchProducts(this.state.products, inputSearch)


    this.setState({ 
        filteredProducts: newFilteredProducts
        })
    }

    getProduct = (product: IProduct) => {
        let item = this.state.products.find((currProduct) => {
            return currProduct.ITEMID === product.ITEMID;
        });
        return item;
    }

    handleControlProduct = (product:IProduct, cart:Array<IProduct>, farm:IFarm, qty:number) =>{
        let result:Array<number|number> = controlProduct(product, cart, farm, qty);
        switch(result[0]){
            case 0: // Todo correcto
                break;
            case 1: 
                this.handleAlertQtyProduct(`¡ATENCIÓN! Se ha ajustado la cantidad al cajón del camión. ¿Quiere ir a la distribución de pienso?`, 2, 1, product);
                break;
            case 2: // Carga máxima del camión sobrepasada
                this.handleAlertQtyProduct(`¡ATENCIÓN! La cantidad excede el límite del camión (${farm.truckLoad} Kg), se ajustará la cantidad. ¿Quiere ir a la distribución de pienso?`, 2, 1, product);
                break;
            case 3: // Cantidad máxima de producto sobrepasada
                this.handleAlertQtyProduct(`¡ATENCIÓN! La cantidad excede el límite de la etapa, se ajustará la cantidad. ¿Quiere ir a la distribución de pienso?`, 2, 1, product);
                break;
            case 4: // No hay más cajones disponibles
                this.handleAlertQtyProduct(`¡ATENCIÓN! No hay más cajones disponibles.`, 1, -1);
                break;
            case -1:
                this.handleAlertQtyProduct(`¡ATENCIÓN! La cantidad permitida no completa 1 cajón.`, 1, -1);
                break;
        }
        return result[1];
    }

    handleAlertQtyProduct=(text:string, modalType:number, action:number, product?:IProduct)=>{
        this.setState({
            inputText: text,
            modalType: modalType,
            typeAction: action,
            creationModalOpen: true,
            selectedProduct:product,
            titleModal:'MENSAJE'
        })
    }

    handleAddUnitClick = (evt: any, index: number) => {
        evt.stopPropagation();

        let newProducts: Array<IProduct> = cloneDeep(this.state.filteredProducts);
        
        let quantity = newProducts[index].quantity ? newProducts[index].quantity : 0;
        if (quantity !== undefined && this.state.farm !== undefined) {
            quantity += 1;
        }

        newProducts[index].quantity = quantity;

        
        this.props.updateCart(newProducts[index], true);
        this.setState({ filteredProducts: newProducts });
    }

    handleRemoveUnitClick = (evt: any, index: number) => {
        evt.stopPropagation();

        let newProducts: Array<IProduct> = cloneDeep(this.state.filteredProducts);

        let quantity = newProducts[index].quantity ? newProducts[index].quantity : 0;
        if (quantity !== undefined && quantity > 0 && this.state.farm !== undefined) {
            quantity -= 1;
        }

        newProducts[index].quantity = quantity;

        this.props.updateCart(newProducts[index], true);

        this.setState({ filteredProducts: newProducts });
    }

    handleClickManualUnit = (evt: any) => {
        evt.stopPropagation();
    }

    handleBlurManualUnit = (evt: any, index: number) => {
        let newProducts: Array<IProduct> = cloneDeep(this.state.filteredProducts);
        let isInCart = newProducts[index].isInCart;
        let qty = newProducts[index].quantity
        if(newProducts[index].TIPO == 1 && qty !== undefined && this.state.farm!== undefined){
            let result = isInCart?this.handleControlProduct(newProducts[index], this.props.cart, this.state.farm, qty):qty;
            if(qty == result)
                return;

            newProducts[index].quantity = result;

            this.props.updateCart(newProducts[index], true);
    
            this.setState({ filteredProducts: newProducts });
        }
    }

    handleChangeManualUnit = (evt: any, index: number) => {
        evt.stopPropagation();
        let newProducts: Array<IProduct> = cloneDeep(this.state.filteredProducts);
       
        let quantity = Math.floor(Number(evt.target.value));

        newProducts[index].quantity = quantity;

        this.props.updateCart(newProducts[index], true);
        this.setState({ filteredProducts: newProducts });
    }

    handleCreateModal = (typeAction: number) => {
        switch(typeAction){
            case 1: // Abre la distribución del pienso
                if(this.state.selectedProduct == undefined)
                    return;
                this.handleRenderPicker(this.state.selectedProduct);
                break;
        }
    }

    handleControlSiloProduct = (silo:ISilo, product: IProduct, qty:number) =>{
        let result:Array<number|number> = controlSiloProduct(silo, product, qty);

        switch(result[0]){
            case 1: // Cantidad de silo excedida
                break;
            default:
                break;
        }
        return result[1];
    }

    handleAddSilo=(nave:INave, silo:ISilo, product:IProduct)=>{
        let newProduct = cloneDeep(product);
        let indexSilo = findIndex(newProduct.silos, function(o){return o.NAVEID == nave.NAVEID && o.SILOID == silo.SILOID});

        if(indexSilo !== -1) {
            newProduct.silos.splice(indexSilo, 1);
        } else{
            silo.orden = getNextOrden(newProduct.silos);
            silo.quantity = 0 //getQuantitySilo(silo.capacidad, product.quantity!==undefined?product.quantity:0, getAcumuladoPienso(newProducts[indexProduct].silos));
            silo.NAVEID = nave.NAVEID;
            newProduct.silos.push(silo);
        }
        newProduct.silos = sortBy(newProduct.silos, [function(o) { return o.orden; }]);
        this.props.updateCart(newProduct);
        this.handleRenderPicker(newProduct)
    }

    handleDeleteSilo=(product:IProduct, indexSilo:number)=>{
        let newProduct = cloneDeep(product);
        newProduct.silos.splice(indexSilo,1);
        this.props.updateCart(newProduct);
        this.handleRenderPicker(newProduct)
    }

    handleConfChangeSilo=(product:IProduct, indexSilo:number, value:any, typeValue:any)=>{
        let newProduct = cloneDeep(product);
        
        newProduct.silos[indexSilo].lleno = false;
        newProduct.silos[indexSilo].resto = false;
        newProduct.silos[indexSilo].quantity = 0;
        switch(typeValue){
            case 1:
                newProduct.silos[indexSilo].quantity = this.handleControlSiloProduct(newProduct.silos[indexSilo], product, Number(value));;
                break;
            case 2:
                newProduct.silos[indexSilo].lleno = value;
                break;
            case 3:
                newProduct.silos[indexSilo].resto = value;
                break;
        }
        this.props.updateCart(newProduct);
        this.handleRenderPicker(newProduct);
    }

    handleRenderPicker= async (product:IProduct)=>{

        if(this.state.farm !== undefined) {
            let newFarm = cloneDeep(this.state.farm);
            let newProduct = cloneDeep(product);

            let newSilos = newProduct.silos.map((silo:ISilo, indexSilo:number)=>{
                return({
                    ...silo,
                    handleDelete:()=>this.handleDeleteSilo(newProduct, indexSilo),
                    handleConfChange:this.handleConfChangeSilo,
                })
            })
            let newBuilding = newFarm.building.map((nave:INave)=>{
                let silos = nave.silo.map((silo:ISilo)=>{
                    return({
                        ...silo,
                        handleAddSilo:()=>this.handleAddSilo(nave, silo,newProduct)
                    })
                })
                return({
                    ...nave,
                    silo:silos
                })
            })
            newProduct.silos=newSilos;
            newFarm.building=newBuilding;

            this.setState({
                titleModal:'DISTRIBUCIÓN PIENSO',
                inputText: <ProductPicker style={{}} index={-1} data={[newFarm, newProduct]} />,
                modalType: 1,
                typeAction: 1,
                creationModalOpen: true
            })
        }
    }

    updateCart = (evt: any, product: IProduct, index: number) => {
        evt.stopPropagation();

        if(product.TIPO == 1 && product.quantity !== undefined){
            if(this.state.farm !== undefined && this.state.farm.building.length > 0) {
                let newQty = product.quantity;

                if(!product.isInCart){ // El artículo entrará en la carga del camión y se hace el control
                    newQty = this.handleControlProduct(product, this.props.cart, this.state.farm, product.quantity);
                }
                if(product.quantity !== newQty){
                    product.quantity = newQty;
                    this.props.addOrRemoveFromCart(product, this.state.farm.id,  this.state.farm.dataareaid);
                    return;
                }
                product.quantity = newQty;
                this.props.addOrRemoveFromCart(product, this.state.farm.id,  this.state.farm.dataareaid);
            } else {
                this.setState({
                    inputText: `¡ATENCIÓN! No hay configurado ningún silo.`,
                    modalType: 1,
                    creationModalOpen: true,
                })
            }
        } else if(this.state.farm !== undefined && product.TIPO !== 1){
            this.props.addOrRemoveFromCart(product, this.state.farm.id, this.state.farm.dataareaid);
        } else {
            this.setState({
                inputText: `¡ATENCIÓN! Se debe especificar una cantidad.`,
                modalType: 1,
                creationModalOpen: true,
            })
        }
        
        if(product.isInCart && product.TIPO == 1 && product.quantity !== undefined){
            this.handleRenderPicker(product);
        }
    }

    onDistributionClick = (evt: any, product: IProduct|undefined, index: number) => {
        evt.stopPropagation();
        if(product == undefined){
            return;
        }
        this.handleRenderPicker(product);
    }

    render() {
        let items = this.state.filteredProducts.filter((item) => {
            return true;
        }).map((product, i) => {
            let inCartProduct = this.props.cart.find((item) => {
                return (item.ITEMID + item.feedStage + item.batchId) === (product.ITEMID + product.feedStage + product.batchId);
            });

            let isInCart = inCartProduct !== undefined ? true : false;
            let isSameFarmRefCart = (this.props.params.farmRef == this.props.cartFarmRef && this.props.params.dataareaid == this.props.cartDataareaidRef)?true:false
            if(inCartProduct !== undefined) {
                product.quantity = inCartProduct.quantity;
                product.isInCart = true;
            }
            return {
                ...product,
                quantity: inCartProduct !== undefined ? inCartProduct.quantity : product.quantity,
                onItemClick: () => this.handleItemClick(product, i),
                onAddUnitClick: (evt: any) => this.handleAddUnitClick(evt, i),
                onRemoveUnitClick: (evt: any) => this.handleRemoveUnitClick(evt, i),
                onClickManualInput: (evt: any) => this.handleClickManualUnit(evt),
                onBlurManualInput: (evt: any) => this.handleBlurManualUnit(evt, i),
                onChangeManualInput: (evt: any) => this.handleChangeManualUnit(evt, i),
                updateCart: (evt: any) => this.updateCart(evt, product, i),
                onDistributionClick:(evt: any) => this.onDistributionClick(evt, inCartProduct, i),
                isInCart,
                isSameFarmRefCart: this.props.cartFarmRef!==undefined && this.props.cartDataareaidRef!==undefined && this.props.cart.length > 0?isSameFarmRefCart:true
            }
        })

        return (
            <AppBar title={'Productos'} navigate={this.props.navigate}>
                <div style={{ marginTop: 15 }}>
                    <SearchField
                        allowSearch={this.state.loading}
                        value={this.state.searchValue?this.state.searchValue:''}
                        onValueChange={this.handleValueChange}
                        placeholder={`Buscar Producto`} />
                </div>
                {!this.state.loading?
                    <div style={{
                        position: 'absolute',
                        top: '65px',
                        left: 0,
                        right: 0,
                        bottom: '56px',
                    }}>
                        {items.length == 0?
                            <div style={{textAlign:'center', padding:50}}>
                                {this.state.searchValue?.length == 0?
                                    <p>
                                        {`Esta granja no tiene productos para visualizar.`}
                                    </p>
                                :
                                    <p>
                                        {`No existe ningún producto con el valor de búsqueda aplicado.`}
                                    </p>
                                }
                            </div> 
                        :
                            <VirtualizedList items={items} RowComponent={ProductListItem} itemSize={145} />}
                    </div>
                :
                    <div style={{marginLeft:'50%',marginTop:200}}>
                    <Loader loading={true}/>
                    </div>
                }
                <div style={{ position: 'absolute', bottom: 0, left: 0, right: 0 }}>
                    <BottomNavigation
                        // showLabels
                        style={{ backgroundColor: '#ddd' }}
                    >
                        <CreateModal
                            onCloseCreationModal={this.onCloseCreationModal}
                            isOpen={this.state.creationModalOpen}
                            inputText={this.state.inputText}
                            typeModal={this.state.modalType}
                            action={this.handleCreateModal}
                            typeAction={this.state.typeAction}
                            loadingModal={this.state.loadingModal}
                            titleModal={this.state.titleModal}
                        />
                        <BottomNavigationAction
                            label="Cesta"
                            icon={
                                <Badge badgeContent={this.props.cart.length} color="secondary">
                                    <TruckIcon />
                                </Badge>
                            }
                            onClick={this.goToCart}
                        />
                    </BottomNavigation>
                </div>
            </AppBar>
        );
    }
}
export default withParamsAndNavigate(withStyles(styles)(Products));