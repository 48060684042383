import React, { Component } from 'react';
import { Theme, withStyles, createStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import HomeIcon from '@material-ui/icons/HomeRounded';
import BackIcon from '@material-ui/icons/ArrowBackIos';

const styles = (theme: Theme) => createStyles({
    root: {
        flexGrow: 1,
    },
    appBar: {
        //backgroundColor: '#284770',
        backgroundColor: '#000000',
    },
    title: {
        flexGrow: 1,
        textAlign: 'center',
    },
    appBody: {
        position: 'absolute',
        top: '56px',
        left: 0,
        right: 0,
        bottom: 0,
        // backgroundColor: '#eee'
    }
});


interface IAppBarProps {
    classes: any;
    title: string;
    navigate: any;
    extraFeatures?: any;
    goBack?: any;
    children?:any;
    // goToFeature: Function;
    // goToBack: (event: MouseEvent) => void;
}

interface IAppBarState {
}

class AppBarComponent extends Component<IAppBarProps, IAppBarState> {
    constructor(props: IAppBarProps) {
        super(props);
        this.state = {
        };
    }

    goHome = () => {
        this.props.navigate(`/`);
    }

    goBack = () => {
        this.props.navigate(-1);
    }

    render() {
        const { classes, title } = this.props;
        return (
            <div className={classes.root}>
                <AppBar position="static">
                    <Toolbar classes={{ root: classes.appBar }}>
                        <IconButton edge="start" color="inherit" aria-label="Menu" onClick={this.props.goBack ? this.props.goBack : this.goBack}>
                            <BackIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            {title}
                        </Typography>
                        {this.props.extraFeatures}
                        <IconButton edge="start" color="inherit" aria-label="Menu" onClick={this.goHome}>
                            <HomeIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <div className={classes.appBody}>
                    {this.props.children}
                </div>
            </div >
        );
    }
}
export default withStyles(styles)(AppBarComponent);