import React, { Component } from 'react';
import AppBar from './AppBar';
import VirtualizedList from './VirtualizedList';
import { IPedido, IFarm } from '../types';
import SearchField from './SearchField';
import OrderService from '../ApiClients/OrderService';
import PedidoListItem from './PedidoListItem';
import Loader from './Loader';
import { CheckNumberPlate, searchPedidos } from '../helpers';
import {cloneDeep, filter, find, findIndex, includes} from 'lodash';
import { SwitchField } from './FormsWidgets';
import { Box, Tab, Tabs } from '@mui/material';
import { withParamsAndNavigate } from './Material/ReactRouter';
import CreateModal from './CreateDialog';
import DeliveryDialog from './DeliveryDialog';

interface IPedidosProps {
    params?:any;
    navigate?:any;    
    type: number;
    title: string;
}

interface IPedidosState {
    pedidos: Array<IPedido>;
    searchValue: string;
    loading: boolean;
    filteredPedidos: Array<IPedido>;
    filterPage: Object;
    tabPedidos: number;

    openDeliveryDialog: boolean;
    inputDeliveryDialog: any;
    titleModal: string;
    loadingModal: boolean;
    modalType: number;
    typeAction: number;
    inputText: string;
    creationModalOpen: boolean;

    tPedido?:IPedido;
}

class Pedidos extends Component<IPedidosProps, IPedidosState> {
    constructor(props: IPedidosProps) {
        super(props);
        this.state = {
            pedidos: [],
            searchValue: '',
            loading: true,
            filteredPedidos:[],
            filterPage: {historic: false},
            tabPedidos: 0,

            openDeliveryDialog: false,
            inputDeliveryDialog: '',
            titleModal: 'MENSAJE',
            loadingModal: false,
            modalType: 0,
            typeAction: 0,
            creationModalOpen: false,
            inputText: ''
        };
    }
   
    onCloseCreationModal = () => {
        this.setState({ creationModalOpen: false });
    }

    onCloseDeliveryDialog = () => {
        this.setState({ openDeliveryDialog: false });
    }

    componentDidMount() {
        this.loadPedidos();
    }

    loadPedidos = async () => {
        let pedidos = await OrderService.getPedidos(true, this.props.type);

        this.setState({
            pedidos,
            loading: false
        }, ()=>this.handleFilterPedidos());
    }

    handleValueChange = (evt: any) => {
        this.setState({ 
            searchValue: evt.target.value,
        }, ()=>this.handleFilterPedidos())
    }

    handleFilterPedidos = () => {
        let newPedidos = cloneDeep(this.state.pedidos);

        //** FILTRA POR EL TIPO DE PEDIDO */
        const tipo = this.props.type;
        const tabPedidos = this.state.tabPedidos;

        if(tipo == 1 && tabPedidos == 0) {
            newPedidos = filter(newPedidos, function(o) {return ![50, 98, 99].includes(o.status)})
        } else if(tipo == 2 && tabPedidos == 0) {
            newPedidos = filter(newPedidos, function(o) {return ![50, 98, 99].includes(o.status)})
        }
        
        //** FILTRA POR EL VALOR DE BÚSQUEDA */
        const searchValue = this.state.searchValue.toLowerCase();

        if(newPedidos.length > 0 && searchValue && searchValue.length > 0){
            newPedidos = filter(newPedidos, function(o){return includes(o.farm?.id.toLowerCase(), searchValue) || includes(o.farm?.name.toLowerCase(), searchValue) || includes(o.idPedido?o.idPedido.toLowerCase():"", searchValue) });
        }

        this.setState({
            filteredPedidos: newPedidos
        })
    }

    handleItemClick = (pedido: IPedido, i: number) => {
        if(this.props.type == 1)
            this.props.navigate(`/pedido/${pedido.farmDataareaid}/${pedido.id}`);
        else if(this.props.type == 2)
            this.props.navigate(`/delivery/${pedido.farmDataareaid}/${pedido.id}`);
    }

    handleItemEditClick = (e: any, pedido: IPedido, i: number) => {
        e.stopPropagation();

        this.setState({
            tPedido: pedido
        }, ()=>this.handleEditPedido())
    }

    handleEditPedido = () => {
        if(!this.state.tPedido)
            return;

        let n_pedido = cloneDeep(this.state.tPedido);

        n_pedido.handleChange = (id: string, evt: any) => this.handleHeaderChange(id, evt, n_pedido);


        this.setState({
            titleModal:'EDICIÓN DATOS',
            inputDeliveryDialog: <DeliveryDialog style={{}} index={-1} data={[2, n_pedido]} />,
            modalType: 5,
            typeAction: 1,
            openDeliveryDialog: true
        })
    }

    handleHeaderChange = (id: string, evt: any, pedido:IPedido) => {
        let n_pedido = cloneDeep(pedido);
        n_pedido[id] = evt.target.value;

        if(id == "deliveryTruckNumberplate" || id == "deliveryTrailerNumberplate")
            n_pedido[id] = n_pedido[id]?.trim().toUpperCase();

        this.setState({
            tPedido: n_pedido
        }, ()=>this.handleEditPedido())
    }

    saveEditPedido = async () => {
        const {tPedido} = this.state;
        // Verifica que la tienda y la fecha del carrito no sean undefined
        if(tPedido && tPedido.farm && tPedido.date && tPedido.id) {
            let Pedido: IPedido = cloneDeep(tPedido);


            let responsePedido : number = await OrderService.saveData(Pedido, 3);

            let text = "¡ATENCIÓN! Se ha producido un error y no se han podido actualizar los datos del pedido.";
            let modalType = 1;
            let typeAction = -1;

            switch(responsePedido){ // Condicional para verificar que no ha habido ningun error en el backend
                case 1:
                    text = "Se ha actualizado correctamente.";
                    modalType = 1;
                    typeAction = 0;

                    
                    this.setState({
                        inputText: text,
                        modalType: modalType,
                        creationModalOpen: true,
                        typeAction:typeAction,
                        loadingModal: false,
                        openDeliveryDialog: false,
                        loading: true
                    }, ()=>this.loadPedidos())
                    break;
                default: 
                    this.setState({
                        inputText: text,
                        modalType: modalType,
                        creationModalOpen: true,
                        typeAction:typeAction,
                        loadingModal: false,
                        openDeliveryDialog: false,
                    })
                    break;
            }

        } 
    }

    handleCreateModal = (typeAction: number) => {
        switch(typeAction) {
            case 1: // Cuando el modal es para abrir el modal de actualizar datos
                const {tPedido} = this.state;
                console.log(tPedido)

                if(tPedido && tPedido.farm){
                    // Controla los campos obligatorios
                    if(!tPedido.deliveryTruckNumberplate || !CheckNumberPlate(1, tPedido.deliveryTruckNumberplate))
                        this.setState({
                            inputText: `¡ATENCIÓN! Tiene que informar una matrícula de camión válida.`,
                            modalType: 1,
                            creationModalOpen: true,
                            typeAction: -1
                        })
                    else if(!tPedido.deliveryTrailerNumberplate || !CheckNumberPlate(2, tPedido.deliveryTrailerNumberplate))
                        this.setState({
                            inputText: `¡ATENCIÓN! Tiene que informar una matrícula de remolque válida.`,
                            modalType: 1,
                            creationModalOpen: true,
                            typeAction: -1
                        })
                    else
                    this.setState({
                        inputText: `¿Quiere actualizar los datos del pedido de la granja ${tPedido.farm.name}?`,
                        modalType: 2,
                        creationModalOpen: true,
                        typeAction: 2
                    })
                }
                break;
            case 2: // Cuando el modal es para guardar los datos actualizados
                this.setState({
                    loadingModal: true
                }, ()=>this.saveEditPedido())
                break;
            default:
                break;
        }
    }

    renderPedidos=(type:number)=>{
        if(this.state.loading){
            return(
                <div style={{marginLeft:'50%',marginTop:200}}>
                    <Loader loading={true}/>
                </div>
            );
        }
        else if(type == 1) {
            const {filteredPedidos} = this.state;
            if(filteredPedidos.length == 0)
                return (
                    <div style={{textAlign:'center', padding:50}}>
                        <p>
                            {`No tiene pedidos realizados para visualizar.`}
                        </p>
                    </div>
                )
            let items = filteredPedidos.map((pedido, i) => {
                return {
                    ...pedido,
                    onItemClick: () => this.handleItemClick(pedido, i),
                }
            })
            return(
                <VirtualizedList items={items} RowComponent={PedidoListItem} itemSize={130} />
            );
        }
        else if(type == 2) {
            const {filteredPedidos} = this.state;
            if(filteredPedidos.length == 0) {
                if(this.state.tabPedidos == 0)
                    return (
                        <div style={{textAlign:'center', padding:50}}>
                            <p>
                                {`No tiene pedidos pendientes de entregar para visualizar.`}
                            </p>
                        </div>
                    )
                else if(this.state.tabPedidos == 1)
                    return (
                        <div style={{textAlign:'center', padding:50}}>
                            <p>
                                {`No tiene pedidos pendientes de entregar o entregados para visualizar.`}
                            </p>
                        </div>
                    )
                else
                    return (
                        <div style={{textAlign:'center', padding:50}}>
                            <p>
                                {`No hay datos a visualizar.`}
                            </p>
                        </div>
                    )
            }

            let items = filteredPedidos.map((pedido, i) => {
                return {
                    ...pedido,
                    onItemClick: () => this.handleItemClick(pedido, i),
                    onItemEditClick: (e:any) => this.handleItemEditClick(e, pedido, i),
                }
            })
            return(
                <VirtualizedList items={items} RowComponent={PedidoListItem} itemSize={170} />
            );
        }
    }

    handleSwichChangeUser=(id:string, evt:any)=>{
        let newRegister:any = cloneDeep(this.state.filterPage);
        newRegister[id] = evt.target.checked;
        this.setState({filterPage:newRegister});
    }

    handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
        this.setState({
            tabPedidos: newValue
        }, ()=>this.handleFilterPedidos())
    };

    render() {

        const {type, title} = this.props;
        const {filterPage, tabPedidos} = this.state;

        return (
            <AppBar title={title} navigate={this.props.navigate}>
                {/* <div style={{ paddingTop: '10px' }}>
                    <SearchField allowSearch={this.state.loading} value={this.state.searchValue} onValueChange={this.handleValueChange} placeholder="Buscar Pedido" />
                </div> */}
                <div style={{ paddingTop: '15px' }}>
                    <table style={{width:'100%'}}>
                        <tbody>
                            <tr>
                                <SearchField allowSearch={this.state.loading} value={this.state.searchValue} onValueChange={this.handleValueChange} placeholder="Buscar Pedido" />                            </tr>
                            <tr>
                                <td>
                                    
                                    <Box sx={{ maxWidth: { xs: 320, sm: 480 }, bgcolor: 'background.paper', marginLeft: '10px', marginTop: '5px' }}>
                                        <Tabs
                                            value={tabPedidos}
                                            onChange={this.handleChangeTab}
                                            variant="scrollable"
                                            scrollButtons="auto"
                                            aria-label="scrollable auto tabs example"
                                        >
                                            <Tab label={`Pendientes`} />
                                            <Tab label={`Todos`} />
                                        </Tabs>
                                    </Box>
                                    {/* <SwitchField
                                        key={0}
                                        name={`historic`}
                                        id={`historic`}
                                        fieldsData={filterPage}
                                        handleFieldChange={this.handleSwichChangeUser}
                                        disabled={false}
                                        color="secondary"
                                    />
                                    {`Histórico`} */}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div style={{
                    position: 'absolute',
                    top: 130,
                    left: 0,
                    right: 0,
                    bottom: 0,
                }}>
                    {this.renderPedidos(type)}
                </div>

                {this.state.openDeliveryDialog?
                    <CreateModal
                        onCloseCreationModal={this.onCloseDeliveryDialog}
                        isOpen={this.state.openDeliveryDialog}
                        inputText={this.state.inputDeliveryDialog}
                        typeModal={this.state.modalType}
                        action={this.handleCreateModal}
                        typeAction={this.state.typeAction}
                        loadingModal={this.state.loadingModal}
                        titleModal={this.state.titleModal}
                        zIndex={0}
                    />
                :
                    null
                }
                {this.state.creationModalOpen?
                    <CreateModal
                        onCloseCreationModal={this.onCloseCreationModal}
                        isOpen={this.state.creationModalOpen}
                        inputText={this.state.inputText}
                        typeModal={this.state.modalType}
                        action={this.handleCreateModal}
                        typeAction={this.state.typeAction}
                        loadingModal={this.state.loadingModal}
                        titleModal={this.state.titleModal}
                        zIndex={99}
                    />
                :
                    null
                }
            </AppBar>
        );
    }
}
export default withParamsAndNavigate(Pedidos);