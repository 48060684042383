import { IFarm } from '../types';

let listFarms:any =undefined;

let listProducts:any=undefined;

let listPedidos:any=undefined;

let listPedidosRelated:any=undefined;

class appData {

    static getListFarms(){
        return listFarms;
    }

    static setListFarms(newListFarms:any){
        listFarms=newListFarms;
    }

    static getListProducts(){
        return listProducts;
    }

    static setListProducts(newListProducts:any){
        listProducts=newListProducts;
    }

    static getListPedidos(){
        return listPedidos;
    }

    static setListPedidos(newListPedidos:any){
        listPedidos=newListPedidos;
    }
    
    static getListPedidosRelated(){
        return listPedidosRelated;
    }

    static setListPedidosRelated(newListPedidosRelated:any){
        listPedidosRelated=newListPedidosRelated;
    }
}
export default appData;