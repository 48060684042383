import React, { Component } from 'react';
import AppBar from './AppBar';
import PropTypes from 'prop-types';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import VirtualizedList from './VirtualizedList';
import { IFarm, IMe } from '../types';
import SearchField from './SearchField';
import OrderService from '../ApiClients/OrderService';
import Loader from './Loader';
import { searchFarms } from '../helpers'
import { ListChildComponentProps } from 'react-window';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import ArrowIcon from '@material-ui/icons/ArrowForwardIos';
import farmIcon from '../Assets/farm.png';
import { withParamsAndNavigate } from './Material/ReactRouter';

const useStyles = makeStyles(createStyles({
    img: {
        objectFit: 'contain',
        width:40,
        height:40
    },
    root: {
        borderRadius: 0,
    },
    item: {
        // border: '1px solid #ccc',
        borderRadius: '15px',
        margin: '5px 10px',
        width: 'calc(100% - 20px)!important',
        boxShadow: '5px 5px 5px 0px rgba(0,0,0,0.31)',
        cursor: 'pointer',
        paddingBottom: '5px',
        position: 'relative',
        border: '2px solid #ddd'
    },
    icon: {
        paddingTop: '3px',
        paddingBottom: '3px',
        color: '#284770',
        verticalAlign: 'middle',
    },
    separator: {
        display: 'inline-block',
        borderRight: '1px solid rgba(0,0,0,0.31)',
        verticalAlign: 'middle',
        height: '100%',
        marginLeft: '15px'
    },
    textField: {
        marginLeft: '10px',
        marginRight: '10px',
        width: 60,
    },
    mainHeader: {
        whiteSpace:'nowrap',
        overflowX:'auto'
    }
}));

const FarmListItem = (props: any) => {
    const { index, style, data } = props;
    const classes = useStyles();
    let item: IFarm = data[index];

    return (
        <div style={style} key={index}>
            <ListItem classes={{ root: classes.item }}>
                <ListItemIcon>
                    <Avatar alt="Farm Icon" src={farmIcon} classes={{
                        root: classes.root,
                        img: classes.img
                    }} />
                </ListItemIcon>
                <ListItemText onClick={item.onItemClick} primary={item.name} secondary={`${item.id} (${item.dataareaid})`} className={classes.mainHeader} />
                <ListItemSecondaryAction style={{ paddingBottom: '10px' }}>
                    <IconButton edge="end" aria-label="Comments" onClick={item.onItemClick}>
                        <ArrowIcon />
                    </IconButton>
                </ListItemSecondaryAction>
            </ListItem>
        </div>
    );
}

FarmListItem.propTypes = {
    index: PropTypes.number,
    style: PropTypes.object,
} as any;

interface IReviewFarmsProps {
    params?:any;
    navigate?:any;    
    myInfo?: IMe;
}

interface IReviewFarmsState {
    farms: Array<IFarm>;
    searchValue: string;
    loading: boolean;
    filteredFarms: Array<IFarm>
}

class ReviewFarms extends Component<IReviewFarmsProps, IReviewFarmsState> {
    constructor(props: IReviewFarmsProps) {
        super(props);
        this.state = {
            farms: [],
            searchValue: '',
            loading: true,
            filteredFarms:[]
        };
    }

    componentDidMount() {
        if(this.props.myInfo !== undefined && this.props.myInfo.supervisor){
            this.loadPedidosRelated();
        } else {
            this.props.navigate(`/`)
        }
    }

    loadPedidosRelated = async () => {
        let farms = await OrderService.getPedidosRelated(false);
        this.setState({
            farms,
            filteredFarms: searchFarms(farms, this.state.searchValue),
            loading: false
        });
    }

    handleValueChange = (evt: any) => {
        this.setState({ 
            searchValue: evt.target.value,
            filteredFarms: searchFarms(this.state.farms, evt.target.value)
        })
    }

    handleItemClick = (farm: IFarm, i: number) => {
        this.props.navigate(`/pedidos/${farm.dataareaid}/${farm.id}`)
    }

    renderPedidos=()=>{
        if(this.state.loading){
            return(
                <div style={{marginLeft:'50%',marginTop:200}}>
                    <Loader loading={true}/>
                </div>
            );
        }
        else {
            const {filteredFarms} = this.state;
            if(filteredFarms.length == 0)
                return (
                    <div style={{textAlign:'center', padding:50}}>
                        <p>
                            {`No tiene granjas con pedidos realizados para visualizar.`}
                        </p>
                    </div>
                )
            let items = this.state.filteredFarms.map((pedido, i) => {
                return {
                    ...pedido,
                    onItemClick: () => this.handleItemClick(pedido, i),
                }
            })
            return(
                <VirtualizedList items={items} RowComponent={FarmListItem} itemSize={90} />
            );
        }
    }

    render() {

        return (
            <AppBar title={'Supervisión'} navigate={this.props.navigate}>
                <div style={{ paddingTop: '10px' }}>
                    <SearchField allowSearch={this.state.loading} value={this.state.searchValue} onValueChange={this.handleValueChange} placeholder="Buscar granja..." />
                </div>
                <div style={{
                    position: 'absolute',
                    top: 70,
                    left: 0,
                    right: 0,
                    bottom: 0,
                }}>
                {this.renderPedidos()}
                </div>
            </AppBar>
        );
    }
}
export default withParamsAndNavigate(ReviewFarms);