import React, { Component } from 'react';
import { IProduct, ISilo, INave, IFarm } from '../types';
import PropTypes from 'prop-types';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { ListChildComponentProps } from 'react-window';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {sortBy, findIndex} from 'lodash';
import DeleteIcon from '@material-ui/icons/DeleteOutline'
import TextField from '@material-ui/core/TextField';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import {allowViewSilo, getAcumuladoPienso} from '../helpers';

const useStyles = makeStyles(createStyles({
    itemDistribucion: {
        border: '2px solid #ccc',
        borderRadius: '15px',
        width: 'calc(100% - 20px)!important',
        boxShadow: '5px 5px 5px 0px rgba(0,0,0,0.50)',
        cursor: 'pointer',
        padding: '5px',
        position: 'relative',
    },
    tablecell: {
        fontSize: 12
    }
}));

const SilosListItem = (data:IFarm, product:IProduct) => {
    let areAvailableSilos = false;
    let availableSilos = data.building.map((nave:INave)=>{
        let listSilos = nave.silo.map((silo:ISilo)=>{
            let findSilo = findIndex(product.silos, function(o){return o.SILOID==silo.SILOID});
            
            if(findSilo ==-1 && allowViewSilo(silo, product, 1)) {
                areAvailableSilos=true;
                return (
                    <FormControlLabel key={silo.SILOID}
                    control={<Checkbox key={silo.SILOID} checked={findSilo!==-1?true:false} onChange={()=>silo.handleAddSilo(nave, silo, product)} value={silo.SILOID} />}
                    //label={`Silo: ${silo.id} ${findSilo!==-1?` -> ${product.silos[findSilo].quantity} ${product.silos[findSilo].ud} -> Orden: ${product.silos[findSilo].orden}`:''}`}
                    label={`Silo: ${silo.SILOID}`}
                    />
                )
            }
        })
        return (listSilos);
    });
    return [areAvailableSilos, availableSilos];
}

SilosListItem.propTypes = {
    index: PropTypes.number,
    style: PropTypes.object,
} as any;


const ProductPicker = (props: ListChildComponentProps) => {
    const { index, style, data } = props;
    const classes = useStyles();
    let farm: IFarm = data[0];
    let product: IProduct = data[1];
    let silos = SilosListItem(farm, product)
    //let cantidadRestante =  (product.quantity?product.quantity:0) - getAcumuladoPienso(product.silos);
    /*const [state, setState] = React.useState({
        checkedA: true,
        checkedB: true,
        checkedF: true,
        siloSelected: -1
    });

    const handleSelectedRow = (name:any, siloID:any) => (event:any) => {
        setState({ ...state, [name]: siloID });
    }
    
    const handleChange = (name:any) => (event:any) => {
        setState({ ...state, [name]: event.target.checked });
    };*/

    return (
        <div style={{overflowY:'scroll', height:'50vh', overflowX:'hidden'}}>
            <div className={classes.itemDistribucion}>
                <p style={{fontSize:13}}>{`Producto: ${product.ITEMNAME}`}</p>
                <p style={{fontSize:13}}>{`Cantidad pedida: ${product.quantity?product.quantity:0} ${product.unitId}`}</p>
                {/*<p style={{fontSize:13}}>{`Cantidad restante: ${cantidadRestante} ${product.unitId}`}</p>*/}
            </div>
            <br/>
            <div style={{display:'flex', marginLeft:'5px'}}>
            <FormControl component="fieldset" style={{padding:'3'}}>
                <FormLabel style={{marginBottom:'15px'}} component="legend">{`Silos seleccionados`}</FormLabel>

                <Paper style={{display:'flex'}}>
                    <Table style={{width:15}}>
                        <TableHead> 
                        <TableRow>
                            <TableCell padding="checkbox" size="small" className={classes.tablecell} align="center">{`Silo`}</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {product.silos.map((row:ISilo, index:number) => (
                            <TableRow key={index}>
                                <TableCell style={{paddingBottom:'4px'}} padding="checkbox" size="small" align="center"><strong>{row.SILOID}</strong></TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                <div style={{overflowX:'auto'}}>
                    <Table>
                        <TableHead> 
                        <TableRow>
                            {/*<TableCell padding="checkbox" size="small" className={classes.tablecell} align="center">{`Cantidad`}</TableCell>*/}
                            <TableCell padding="checkbox" size="small" className={classes.tablecell} align="center">{`Orden`}</TableCell>
                            <TableCell padding="checkbox" size="small" className={classes.tablecell} align="center">{`Cantidad`}</TableCell>
                            <TableCell padding="checkbox" size="small" className={classes.tablecell} align="center">{`Lleno`}</TableCell>
                            <TableCell padding="checkbox" size="small" className={classes.tablecell} align="center">{`Resto`}</TableCell>
                            <TableCell style={{color:'#FFFFFF'}} padding="checkbox" size="small" className={classes.tablecell} align="center">{`Eliminar`}</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {product.silos.map((row:ISilo, indexSilo:number) => (
                            <TableRow key={indexSilo}>
                                {/*<TableCell padding="checkbox" size="small" align="center"><IconButton edge="end" aria-label="Comments"><EditIcon /></IconButton></TableCell>*/}
                                {/*<TableCell padding="checkbox" size="small" align="center"><strong>{row.quantity}</strong></TableCell>*/}
                                <TableCell padding="checkbox" size="small" align="center"><strong>{row.orden}</strong></TableCell>
                                <TableCell padding="checkbox" size="small" align="center"><input id='siloQty' type='number' min={1} step={1} style={{paddingLeft:'5px', paddingRight:'5px', width:'50px'}} value={String(row.quantity)} onChange={(evt:any)=>row.handleConfChange(product, indexSilo, evt.target.value, 1)}></input></TableCell>
                                <TableCell padding="checkbox" size="small" align="center">{row.lleno?<Checkbox id='siloLleno'  /*onChange={handleChange('checkedA')}*/ checked={true} onChange={(evt:any)=>row.handleConfChange(product, indexSilo, evt.target.checked, 2)} color="primary" inputProps={{'aria-label': 'primary checkbox',}} />:<Checkbox id='siloLleno'  /*onChange={handleChange('checkedA')}*/ checked={false} onChange={(evt:any)=>row.handleConfChange(product, indexSilo, evt.target.checked, 2)} color="primary" inputProps={{'aria-label': 'primary checkbox',}} />}</TableCell>
                                <TableCell padding="checkbox" size="small" align="center">{row.resto?<Checkbox id='siloResto' /*onChange={handleChange('checkedB')}*/ checked={true} onChange={(evt:any)=>row.handleConfChange(product, indexSilo, evt.target.checked, 3)} inputProps={{'aria-label': 'secondary checkbox',}} />:<Checkbox id='siloResto' /*onChange={handleChange('checkedB')}*/ checked={false} onChange={(evt:any)=>row.handleConfChange(product, indexSilo, evt.target.checked, 3)} inputProps={{'aria-label': 'secondary checkbox',}} />}</TableCell>
                                <TableCell padding="checkbox" size="small" align="left"><IconButton edge="end" aria-label="Comments" onClick={()=>row.handleDelete(row, indexSilo, product)}><DeleteIcon /></IconButton></TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                </div>
                </Paper>

                <FormLabel style={{marginTop:'15px'}} component="legend">{`Silos disponibles`}</FormLabel>
                <FormGroup>
                {silos[0]?
                    silos[1]
                :
                    <p style={{fontSize:15}}>{`No hay silos disponibles`}</p>
                }
                </FormGroup>
                {/*<FormHelperText>Be careful</FormHelperText>*/}
            </FormControl>
            </div>
        </div>
    );
    
}
ProductPicker.propTypes = {
    index: PropTypes.number,
    style: PropTypes.object,
} as any;

export default ProductPicker;